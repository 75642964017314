<template>
  <div>
    <v-card flat>
      <v-card-text>
        <v-alert
          border="left"
          colored-border
          text
          color="primary"
        >
          <span class="font-weight-bold">Select a Connector</span>
        </v-alert>
        <v-row
          class="mb-3 text-center text-caption font-weight-bold"
        >
          <div
            v-for="connector in connectors"
            :key="connector.name"
          >
            <v-card
              class="ma-5 pb-3"
              :style="selectedConnector && selectedConnector.name === connector.name ? 'border: 1px solid grey' : ''"
              @click="connectorSelection(connector)"
            >
              <v-img
                height="300"
                max-height="170"
                max-width="170"
                class="ma-3"
                contain
                :src="connector.avatar"
                :alt="connector.name"
              >
              </v-img>
              <v-row>
                <v-col v-if="!connector.auth">
                  <br>
                </v-col>

                <v-col
                  v-if="connector.auth"
                  sm="12"
                >
                  <v-chip

                    small
                    class="success"
                  >
                    <span class="white--text">Connected</span>
                  </v-chip>
                </v-col>
                <v-col sm="12">
                  <span>{{ connector.name }}</span>
                </v-col>
              </v-row>
            </v-card>
          </div>
        </v-row>
        <!-- Show Connector Auth Connections -->
        <div v-if="connectionId">
          <v-expansion-panels
            v-model="taskPanel"
          >
            <v-expansion-panel>
              <v-expansion-panel-header>
                <span><v-icon left>{{ icons.mdiShieldLockOutline }}</v-icon>Select a <strong>{{ selectedConnector.name }}</strong> Connection <strong>{{ selectedTask ? ' - ' + selectedTask.task : '' }}</strong></span>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <template>
                  <v-list
                    rounded
                  >
                    <v-list-item
                      v-for="item in selectedConnector.auth"
                      :key="item.connectionId"
                      @click="selectedAuthentication = item; taskPanel += 1"
                    >
                      <v-list-item-icon>
                        <v-icon>{{ icons.mdiKeyVariant }}</v-icon>
                      </v-list-item-icon>
                      <v-list-item-content>
                        <v-list-item-title v-text="item.connectionName"></v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                </template>
              </v-expansion-panel-content>
            </v-expansion-panel>
            <!-- Show Connector Task -->
            <v-expansion-panel :disabled="!selectedAuthentication">
              <v-expansion-panel-header>
                <span><v-icon left>{{ icons.mdiCheckboxMarkedCircleOutline }}</v-icon>Select a <strong>{{ selectedConnector.name }}</strong> Task <strong>{{ selectedTask ? ' - ' + selectedTask.task : '' }}</strong></span>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-expansion-panels>
                  <v-expansion-panel v-if="selectedConnector.ingressTasks && selectedConnector.ingressTasks.length > 0">
                    <v-expansion-panel-header>
                      <span><v-icon left>{{ icons.mdiDatabaseArrowLeftOutline }}</v-icon>Get Data <strong>From {{ selectedConnector.name }}</strong></span>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <template>
                        <v-list
                          rounded
                        >
                          <v-list-item-group
                            v-model="ingressTaskSelectionIndex"
                            color="primary"
                          >
                            <v-list-item
                              v-for="item in selectedConnector.ingressTasks"
                              :key="item.id"
                              @click="egressTaskSelectionIndex = null; getTaskProps(item, 'ingress')"
                            >
                              <v-list-item-icon>
                                <v-icon>{{ bindingIcons[item.icon] }}</v-icon>
                              </v-list-item-icon>
                              <v-list-item-content>
                                <v-list-item-title v-text="item.task"></v-list-item-title>
                              </v-list-item-content>
                            </v-list-item>
                          </v-list-item-group>
                        </v-list>
                      </template>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                  <v-expansion-panel v-if="selectedConnector.egressTasks && selectedConnector.egressTasks.length > 0">
                    <v-expansion-panel-header>
                      <span><v-icon left>{{ icons.mdiDatabaseArrowRightOutline }}</v-icon>Send Data <strong>To {{ selectedConnector.name }}</strong></span>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <template>
                        <v-list
                          rounded
                        >
                          <v-list-item-group
                            v-model="egressTaskSelectionIndex"
                            color="primary"
                          >
                            <v-list-item
                              v-for="item in selectedConnector.egressTasks"
                              :key="item.id"
                              @click="ingressTaskSelectionIndex = null; getTaskProps(item, 'egress')"
                            >
                              <v-list-item-icon>
                                <v-icon>{{ bindingIcons[item.icon] }}</v-icon>
                              </v-list-item-icon>
                              <v-list-item-content>
                                <v-list-item-title v-text="item.task"></v-list-item-title>
                              </v-list-item-content>
                            </v-list-item>
                          </v-list-item-group>
                        </v-list>
                      </template>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
              </v-expansion-panel-content>
            </v-expansion-panel>
            <!-- Optional If Task Properties Are Required -->
            <v-expansion-panel
              v-if="selectedConnector"
              v-show="taskProps.taskPropText"
            >
              <v-expansion-panel-header>
                <span><v-icon left>{{ icons.mdiTextBoxMultipleOutline }}</v-icon><strong>{{ taskProps.taskPropText }}</strong></span>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-row>
                  <v-col
                    cols="12"
                    sm="12"
                    md="4"
                  >
                    <v-select
                      v-if="taskProps.taskPropType === 'select'"
                      v-model="taskProps.taskProp"
                      :label="taskProps.taskPropText"
                      :items="taskProps.taskPropList"
                      outlined
                      hide-details
                      dense
                    ></v-select>
                    <v-text-field
                      v-if="taskProps.taskPropType === 'string'"
                      v-model="taskProps.taskProp"
                      :label="taskProps.taskPropText"
                      outlined
                    >
                    </v-text-field>
                  </v-col>
                  <v-col sm="2">
                    <v-btn
                      :disabled="!taskProps.taskProp"
                      color="primary"
                      @click="taskPropExecution()"
                    >
                      Next
                    </v-btn>
                  </v-col>
                </v-row>
              </v-expansion-panel-content>
            </v-expansion-panel>
            <!-- INGESTION ONLY: SELECT DATABASE, SCHEMA, TABLE -->
            <v-expansion-panel v-if="(selectedTask && selectedTask.type === 'ingress')">
              <v-expansion-panel-header>
                <span><v-icon left>{{ icons.mdiDatabaseArrowLeft }}</v-icon><strong>Snowflake Destination</strong></span>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <create-binding-ingestion-expansion-panel
                  @proceed="(taskPanel -= 1)"
                  @setIngestionSchema="schema => $emit('setIngestionSchema', schema)"
                ></create-binding-ingestion-expansion-panel>
              </v-expansion-panel-content>
            </v-expansion-panel>
            <!-- Snow Binding Name -->
            <v-expansion-panel :disabled="!selectedTask">
              <v-expansion-panel-header>
                <span><v-icon left>{{ icons.mdiSquareEditOutline }}</v-icon>Snow Binding Name</span>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-text-field
                  v-model="snowBindingName"
                  label="Snow Binding Name"
                  outlined
                >
                </v-text-field>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
          <v-row
            v-if="connectorErrorMessage"
            class="mt-5"
          >
            <v-col sm="12">
              <v-alert type="error">
                {{ connectorErrorMessage }}
              </v-alert>
            </v-col>
          </v-row>
          <v-row class="mt-5">
            <v-spacer></v-spacer>
            <v-btn
              :loading="loading"
              :disabled="!snowBindingName"
              color="primary"
              class="mr-5 mb-5"
              @click="finishConnectorSelection()"
            >
              Next
            </v-btn>
          </v-row>
        </div>
      </v-card-text>
    </v-card>
    <!-- Dialog: Authentication Dialog Overview -->
    <v-navigation-drawer
      v-if="showAuthenticationDialog"
      temporary
      touchless
      :right="!$vuetify.rtl"
      :width="$vuetify.breakpoint.smAndUp ? 850 : '100%'"
      permanent
      app
    >
      <v-card>
        <v-toolbar
          flat
          class="primary"
        >
          <v-toolbar-title>
            <v-icon
              left
              color="white"
            >
              {{ icons.mdiShieldLockOutline }}
            </v-icon>
            <span class="white--text font-weight-black">
              {{ selectedConnector.name }} Authentication
            </span>
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn
            icon
            @click="showAuthenticationDialog = false"
          >
            <v-icon color="white">
              {{ icons.mdiCloseCircleOutline }}
            </v-icon>
          </v-btn>
        </v-toolbar>
        <auth-dialog @validated="connectionAuthenticated"></auth-dialog>
      </v-card>
    </v-navigation-drawer>
  </div>
</template>

<script>
import { mdiCheckboxMarkedCircleOutline, mdiCloseCircleOutline, mdiDatabaseArrowLeft, mdiDatabaseArrowLeftOutline, mdiDatabaseArrowRightOutline, mdiKeyVariant, mdiShieldLockOutline, mdiSquareEditOutline, mdiTextBoxMultipleOutline } from '@mdi/js'

import { computed, inject, ref, watch } from '@vue/composition-api'

import AuthDialog from './binding-authentication/AuthDialog.vue'

import { bindingIcons } from './BindingCreationScripts'

import createBindingIngestionExpansionPanel from '../binding-views/createBindingIngestionExpansionPanel.vue'

import { cloudFunction } from '@/functions'

export default {
  components: {
    AuthDialog,
    createBindingIngestionExpansionPanel,
  },
  setup(none, { emit }) {
    // Upstream Data
    const snowBindingName = inject('snowBindingName')
    const connectors = inject('connectors')

    // Variables
    const taskPanel = ref(0)
    const connectionId = ref(null)
    const loading = ref(false)
    const showAuthenticationDialog = ref(false)

    // Connector/Task Selection
    const selectedConnector = ref(null) // Emitted to Parent
    watch(selectedConnector, () => emit('setSelectedConnector', selectedConnector.value))
    const selectedTask = ref(null) // Emitted to Parent
    watch(selectedTask, () => emit('setSelectedTask', selectedTask.value))
    const selectedAuthentication = ref(null) // Emitted to Parent
    watch(selectedAuthentication, () => emit('setSelectedAuthentication', selectedAuthentication.value))
    const connector = computed(() => ({ ...selectedConnector, auth: selectedAuthentication.value }))
    const ingressTaskSelectionIndex = ref(null)
    const egressTaskSelectionIndex = ref(null)
    const taskProps = ref({ taskProp: null, taskPropList: [], taskPropType: null, taskPropText: null, taskPropResults: null })
    const ingestionSchema = inject('ingestionSchema')

    // Messages
    const connectorErrorMessage = ref(null)

    // Method When Selecting a Connection
    const connectorSelection = connectorSelected => {
      connectionId.value = null
      selectedTask.value = null
      ingressTaskSelectionIndex.value = null
      egressTaskSelectionIndex.value = null
      selectedAuthentication.value = null
      selectedConnector.value = null
      taskProps.value = { taskProp: null, taskPropList: [], taskPropType: null, taskPropText: null, taskPropResults: null }
      selectedConnector.value = connectorSelected
      if (!selectedConnector.value.auth) {
        showAuthenticationDialog.value = true
      } else {
        const keys = Object.keys(connectorSelected.auth)
        if (!selectedConnector.value.auth) {
          selectedConnector.value.auth = connectorSelected.auth
        }
        // eslint-disable-next-line prefer-destructuring
        connectionId.value = keys[0]
      }
    }

    // Called When Authentication Process Is Complete
    const connectionAuthenticated = auth => {
      showAuthenticationDialog.value = false
      selectedConnector.value.auth = auth
      connectionId.value = auth.connectionId
    }

    // Gets Task Props Data Dynamically By Connector taskPropBindingFunction
    // IF taskPropBindingFunction exists on connector profile a new expansion window will open to collect taskProp
    // Returns i.e. { status: 'SUCCESS', taskPropList: [ 'opportunities' ], taskPropText: 'Select HubSpot Custom Object' , taskPropType: 'select', taskPropResults [optional] Additional Prop Available Data } // select or string types
    const getTaskProps = async (item, type) => {
      try {
        connectorErrorMessage.value = null

        // Set Selected Task
        selectedTask.value = item
        selectedTask.value.type = type
        if (item.taskPropBindingFunction) {
          if (typeof item.taskPropBindingFunction === 'string') {
            // String = FunctionName
            const { data: response } = await cloudFunction({ functionName: item.taskPropBindingFunction, payload: { connector: connector.value } })
            if (response.status === 'ERROR') throw new Error(response.message)
            taskProps.value = { taskProp: null, taskPropList: response.taskPropList || [], taskPropType: response.taskPropType, taskPropText: response.taskPropText, taskPropResults: response.results }
            taskPanel.value += 1
          } else {
            // Object From SnowBinding Definition
            taskProps.value = { taskProp: null, taskPropList: [], taskPropType: null, taskPropText: null, taskPropResults: null }
            taskProps.value = { ...taskProps.value, ...item.taskPropBindingFunction }
            taskPanel.value += 1
          }
        } else {
          taskProps.value = { taskProp: null, taskPropList: [], taskPropType: null, taskPropText: null, taskPropResults: null }
          if (type === 'ingress') {
            taskPanel.value += 3
          } else {
            taskPanel.value += 2
          }
        }
      } catch (error) {
        connectorErrorMessage.value = error
      }
    }

    const taskPropExecution = () => {
      emit('bindingReady', true)
      if (selectedTask.value.type === 'ingress') {
        taskPanel.value += 2
      } else {
        taskPanel.value += 1
      }
    }

    // Gets Bindings Data Dynamically By Connector bindingName - If Data Binding Data Exists
    // Usually Just Returns a functionResponse with Binding Data
    // Can Also Return a Object of { taskPropList, taskPropName, taskPropType, results: response } If Additional Task Props Are Required
    const finishConnectorSelection = async () => {
      try {
        connectorErrorMessage.value = null
        loading.value = true

        // const bindings = await executeBindingFunction({ ...selectedTask.value, account: account.value, user: user.value, connector: connector.value, taskProp: taskProps.value })
        if (selectedTask.value?.bindingFunction) {
          const { data: bindings } = await cloudFunction({ functionName: selectedTask.value.bindingFunction, payload: { ...selectedTask.value, connector: connector.value, taskProp: taskProps.value.taskProp, isBindingCreation: true } })
          if (bindings.status === 'ERROR') throw new Error(bindings.message)
          loading.value = false
          if (bindings) {
            emit('setDataBindings', bindings)
          }
        }
        if (taskProps.value.taskProp) emit('setTaskProp', taskProps.value)
        if (selectedTask.value.type === 'ingress') {
          emit('setIngestionSchema', ingestionSchema.value)
          emit('proceed', 'schedule-job')
        } else {
          emit('proceed', 'build-model-sql')
        }
      } catch (error) {
        loading.value = false
        const errorMessage = `Failed To Finalize Connector Selection and Get Binding Data. ERROR: ${error}`
        console.trace('\u001b[1;31m', errorMessage)
        throw errorMessage
      }
    }

    return {
      ingestionSchema,
      taskPropExecution,
      taskPanel,
      connectors,
      connectionId,
      connectorSelection,
      selectedConnector,
      connectionAuthenticated,
      selectedTask,
      selectedAuthentication,
      ingressTaskSelectionIndex,
      egressTaskSelectionIndex,
      getTaskProps,
      connectorErrorMessage,
      showAuthenticationDialog,
      taskProps,
      loading,
      snowBindingName,
      finishConnectorSelection,
      bindingIcons,
      icons: {
        mdiDatabaseArrowLeft,
        mdiShieldLockOutline,
        mdiKeyVariant,
        mdiCheckboxMarkedCircleOutline,
        mdiDatabaseArrowLeftOutline,
        mdiDatabaseArrowRightOutline,
        mdiTextBoxMultipleOutline,
        mdiSquareEditOutline,
        mdiCloseCircleOutline,
      },
    }
  },
}
</script>

<style lang="scs" scoped>
</style>
