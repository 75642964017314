<template>
  <div class="mt-5 mb-5">
    <v-expansion-panels
      v-model="activePanel"
    >
      <v-expansion-panel
        v-for="(sheet, i) in bindingData.bindingDataArray"
        :key="sheet.multiSheetId"
        class="mt-5"
      >
        <v-expansion-panel-header
          style="height: 60px"
          light
        >
          <v-row>
            <v-col sm="10">
              <span>
                <v-icon left>
                  {{ icons.mdiTableLargePlus }}
                </v-icon>
                <strong>{{ !sheet.googleWorkbookName || !sheet.googleSheetsName ? `Google Sheet # ${i + 1}` : `Google Sheet | ${sheet.googleWorkbookName} [${sheet.googleSheetsName}]` }}</strong>
              </span>
              <span
                v-if="!sheet.bindingsReady"
                class="error--text ml-3"
              ><strong>Bindings Not Completed</strong></span>
            </v-col>
          </v-row>
          <v-col
            sm="2"
            align="end"
          >
            <v-btn
              v-if="bindingData.bindingDataArray.length > 1"
              class="mr-3"
              color="error"
              small
              @click="deleteSheet(i)"
            >
              Delete Sheet
            </v-btn>
          </v-col>
        </v-expansion-panel-header>
        <v-expansion-panel-content class="mt-4">
          <google-sheets-module
            :is-sheet-multi-sheet="true"
            :multi-sheet-data="sheet"
            @setEmittedBindingData="setEmittedBindingData"
          ></google-sheets-module>
          <v-row class="mt-5">
            <v-col align="end">
              <v-btn
                v-if="sheet.bindingsReady"
                color="primary"
                @click="addSheet()"
              >
                Add Sheet
              </v-btn>
            </v-col>
          </v-row>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>
<script>
import { mdiTableLargePlus } from '@mdi/js'

import { inject, onMounted, ref, watch } from '@vue/composition-api'

import googleSheetsModule from './googleSheetsBindings.vue'

import { googleSheetsObjectSchema } from './googleSheetScripts'

export default {
  components: {
    googleSheetsModule,
  },
  setup(none, { emit }) {
    // ————————————————————————————————————
    //* ——— Parent Injections
    // ————————————————————————————————————
    const isEdit = inject('isEdit')

    // const docId = inject('docId')
    // const account = inject('account')
    // const user = inject('user')
    // const selectedConnector = inject('selectedConnector')
    // const queryData = inject('queryData')
    // const selectedTask = inject('selectedTask')
    // const snowBindingName = inject('snowBindingName')
    // const bindingAuth = inject('bindingAuth')

    // ————————————————————————————————————
    //* ——— UI Variables
    // ————————————————————————————————————
    const activePanel = ref(0)

    // const loading = ref(false)
    // const errorMessage = ref(null)
    // const successMessage = ref(null)

    // ————————————————————————————————————
    //* ——— BindingData Object
    // ————————————————————————————————————
    const bindingData = ref({
      cron: null,
      scheduleCreation: null,
      bindingDataArray: [],
    })

    // ————————————————————————————————————
    //* ——— Binding Functions
    // ————————————————————————————————————
    function checkIfAllBindingsReady() {
      const bindingsReadyArray = bindingData.value.bindingDataArray.map(m => m.bindingsReady)
      if (bindingsReadyArray.includes(false)) bindingData.value.bindingsReady = false
      else bindingData.value.bindingsReady = true
    }

    function setEmittedBindingData(emittedData) {
      // Remove Unneeded Elements For MultiSheet
      const { cron, scheduleCreation, ...sheetBindingData } = emittedData

      // Check To See If Sheet Already Exists In Data
      const sheetIndex = bindingData.value.bindingDataArray.findIndex(i => i.multiSheetId === sheetBindingData.multiSheetId)
      if (sheetIndex > -1) {
        bindingData.value.bindingDataArray.splice(sheetIndex, 1, sheetBindingData)
      } else bindingData.value.bindingDataArray.push(sheetBindingData)

      // Check IF All Bindings Are Ready
      checkIfAllBindingsReady()
      emit('setEmittedBindingData', bindingData.value)
    }

    function addSheet() {
      bindingData.value.bindingDataArray.push({ ...googleSheetsObjectSchema, multiSheetId: Date.now() })
      activePanel.value += 1
      checkIfAllBindingsReady()
      emit('setEmittedBindingData', bindingData.value)
    }

    function deleteSheet(index) {
      bindingData.value.bindingDataArray.splice(index, 1)
      checkIfAllBindingsReady()
      emit('setEmittedBindingData', bindingData.value)
    }

    // ———————————————————————————————————————————————————————
    //* ——— Sync In Data From Binding Edit Object - If Edit
    // ———————————————————————————————————————————————————————
    const editBindingObject = inject('bindingData')
    watch(editBindingObject, () => {
      bindingData.value = editBindingObject.value
    })

    // —————————————————————————————————————————————————————————
    //* ——— Binding Initiation - Sets Binding Object if Edit
    // —————————————————————————————————————————————————————————
    onMounted(async () => {
      if (isEdit.value) {
        bindingData.value = editBindingObject.value
        checkIfAllBindingsReady()
        emit('setEmittedBindingData', bindingData.value)
      } else {
        bindingData.value.bindingDataArray.push({ ...googleSheetsObjectSchema, multiSheetId: Date.now() })
      }
    })

    return {
      // Binding Data
      bindingData,

      // UI Variables
      activePanel,

      // Binding Functions
      setEmittedBindingData,
      addSheet,
      deleteSheet,

      icons: {
        mdiTableLargePlus,
      },
    }
  },
}
</script>
