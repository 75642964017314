<template v-if="selectedTask && selectedConnector">
  <v-card flat>
    <v-card-text>
      <v-row>
        <v-col>
          <v-alert
            border="left"
            colored-border
            text
            color="primary"
          >
            <span class="font-weight-bold">Build a Data Model with Snowflake SQL - {{ selectedTask.task }}</span>
          </v-alert>
        </v-col>
      </v-row>
      <!-- API Docs -->
      <v-row
        v-if="dataBindings"
        class="mb-5"
      >
        <v-col
          v-if="selectedTask && selectedTask.apiDocs"
          sm="12"
        >
          <span>
            <strong>{{ selectedConnector.name }} API Docs: <a
              :href="selectedTask.apiDocs"
              target="_blank"
            >Here</a></strong>{{ selectedTask.docsRequireLogin ? ' (Requires Login)' : '' }}
          </span>
        </v-col>
        <v-expansion-panels v-if="selectedConnector && selectedConnector.name">
          <v-expansion-panel v-if="dataBindings.length > 0">
            <v-expansion-panel-header>
              <span><v-icon left>{{ icons.mdiFileTableBox }}</v-icon><strong>{{ selectedConnector.name }} API Data Elements - {{ selectedTask && selectedTask.task ? selectedTask.task : '' }}</strong></span>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <template>
                <v-data-table
                  dense
                  :headers="bindingHeaders"
                  :items="dataBindings"
                  :items-per-page="10"
                  class="text-no-wrap"
                ></v-data-table>
              </template>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-row>
      <v-row>
        <v-col
          sm="12"
          class="mb-3"
        >
          <span><strong>Snippets: </strong> Add the snippet <strong><span v-pre>{{ lastRunTimestamp }}</span></strong> to the query to get the last successful STARTING run timestamp. Helpful for getting modified records after the start of the previous run.</span>
        </v-col>
        <v-textarea
          v-model="sqlQuery"
          label="SQL Query"
          outlined
          shaped
          height="400"
          font-size="26px"
        ></v-textarea>
      </v-row>
      <v-row>
        <v-col v-if="alert.message">
          <v-alert
            :type="alert.type"
            dense
            text
            :color="alert.type"
            class="mb-0"
          >
            {{ alert.message }}
          </v-alert>
        </v-col>
      </v-row>
      <v-row
        class="justify-center"
      >
        <v-btn
          :disabled="!sqlQuery"
          :loading="queryLoading"
          color="primary"
          class="mr-5 mb-5"
          @click="validateQuery"
        >
          Validate Query
        </v-btn>
        <v-btn
          v-if="queryResults.length > 0"
          :disabled="queryChanged"
          color="info"
          class="mr-5 mb-5"
          @click="$emit('proceed', 'bind-data')"
        >
          {{ queryChanged ? 'Query Changed' : 'Results Look Good, Next' }}
        </v-btn>
      </v-row>
      <v-alert
        v-if="limitEnforced && (!alert || JSON.stringify(alert) === '{}')"
        dense
        text
        color="primary"
        type="info"
        class="mt-3"
      >
        {{ limitEnforced }}
      </v-alert>
      <template
        v-if="headers.length > 0 && queryResults.length > 0 && !queryError"
        class="mt-3"
      >
        <v-data-table
          :headers="headers"
          :items="queryResults"
          :items-per-page="10"
          class="elevation-1"
        ></v-data-table>
      </template>
    </v-card-text>
  </v-card>
</template>
<script>
import { snowflakeQuery } from '@/snowflake'

import store from '@/store'

import { mdiFileTableBox } from '@mdi/js'

import { computed, inject, onMounted, ref, watch } from '@vue/composition-api'

export default {
  // eslint-disable-next-line no-unused-vars
  setup(props, { emit }) {
    const selectedConnector = inject('selectedConnector')
    const bindingAuth = inject('bindingAuth')
    const selectedTask = inject('selectedTask')
    const dataBindings = inject('dataBindings')
    const bindingData = inject('bindingData')
    const accountSchema = inject('accountSchema')
    const sqlQuery = ref(null)
    const executedQuery = ref(null)
    const queryChanged = ref(true)
    const headers = ref([])
    const queryResults = ref([])
    const limitEnforced = ref(false)
    const queryError = ref(null)
    const queryLoading = ref(false)
    const alert = ref({})
    const snowflakeUser = ref(null)
    const snowflakeRole = ref(null)

    onMounted(() => {
      if (bindingData.value) {
        sqlQuery.value = bindingData.value.sqlQuery
        emit('queryResultsSetter', { queryResults: [], headers: [], query: sqlQuery.value })
      }

      // Get Snowflake User/Role
      snowflakeUser.value = bindingAuth?.value?.snowflakeCredentials?.username || null
      snowflakeRole.value = bindingAuth?.value?.snowflakeCredentials?.role || null
      if (bindingAuth?.value?.snowflakeCredentials?.isSystem) {
        const account = store.state.accountProfile
        snowflakeUser.value = account.snowflakeCredentials.username
        snowflakeRole.value = account.snowflakeCredentials.role
      }
    })

    watch([sqlQuery, queryChanged], ([sqlQueryTo]) => {
      if (sqlQueryTo === executedQuery.value) queryChanged.value = false
      else queryChanged.value = true

      emit('setQueryReady', !queryChanged.value)
    })

    const dataBindingKeys = computed(() => {
      const keys = []
      dataBindings.value.forEach(record => {
        Object.keys(record).forEach(key => {
          if (!keys.includes(key)) keys.push(key)
        })
      })

      return keys
    })

    const bindingHeaders = computed(() => {
      const headersArray = []
      if (dataBindingKeys.value.includes('group')) headersArray.push({ text: 'Grouping', value: 'group', align: 'start' })
      if (dataBindingKeys.value.includes('name')) headersArray.push({ text: 'Object', value: 'name', align: 'start' })
      if (dataBindingKeys.value.includes('label')) headersArray.push({ text: 'Label', value: 'label', align: 'start' })
      if (dataBindingKeys.value.includes('required')) headersArray.push({ text: 'Required', value: 'required', align: 'start' })
      if (dataBindingKeys.value.includes('unique')) headersArray.push({ text: 'Must Be Unique', value: 'unique', align: 'start' })
      if (dataBindingKeys.value.includes('type')) headersArray.push({ text: 'Data Type', value: 'type', align: 'start' })
      if (dataBindingKeys.value.includes('desc')) headersArray.push({ text: 'Description', value: 'desc', align: 'start' })

      return headersArray
    })

    const buildTable = rows => {
      if (!rows || rows.length === 0) {
        headers.value = []
        queryResults.value = []
        alert.value = { type: 'info', message: 'Your query returned 0 results' }

        return
      }
      let headerRows = Object.keys(rows[0])
      headerRows = headerRows.map(m => ({ text: m, value: m }))
      headers.value = headerRows
      queryResults.value = rows
      if (headerRows.length > 0) queryChanged.value = false

      // Emit QueryResults to Parent
      emit('queryResultsSetter', { queryResults: queryResults.value, headers: headers.value, query: sqlQuery.value })
    }

    const parseSqlQuery = queryParse => {
      let query = queryParse
      let lastRunTimestamp = bindingData?.value?.lastSuccessfulQueryExecutionStartAt || new Date(0)

      // Replace Snippet Text
      if (lastRunTimestamp?.seconds) {
        lastRunTimestamp = new Date(lastRunTimestamp.seconds * 1000)
      }
      lastRunTimestamp = lastRunTimestamp.toISOString()
      const re = /\{\{ lastRunTimestamp \}\}|\{\{lastRunTimestamp\}\}/gi
      query = query.replace(re, `'${lastRunTimestamp}'::timestamp`)

      return query
    }

    const validateQuery = async () => {
      alert.value = {}
      queryError.value = null
      limitEnforced.value = false
      headers.value = []
      queryResults.value = []
      queryLoading.value = true
      executedQuery.value = sqlQuery.value
      let query = sqlQuery.value.replace(/;/g, '')

      // Parse Possible Snippets
      query = parseSqlQuery(query)
      if (!query.toLowerCase().includes('limit')) {
        query += '\nlimit 10;'
        limitEnforced.value = 'We limited your preview results to 10 rows. If you need to preview more rows use "LIMIT" in your query'
      } else {
        limitEnforced.value = 'You are using "LIMIT" in your query and this will limit your final results. If this is for previewing only make sure to remove the "LIMIT" clause before moving on.'
      }
      try {
        // todo: Change To Connector Authentication - Currently Using SF User
        // Get Snowflake User and Credentials

        // Testing by writing to a temp table... This ensures there are not duplicate column names or other issues
        const tableQuery = `create or replace temporary table ${accountSchema.value}.tempTableVerification as ${query}`
        const getResultsQuery = `select * from ${accountSchema.value}.tempTableVerification`
        const getResponse = await snowflakeQuery({ queries: [tableQuery, getResultsQuery], runOn: 'account' })
        const response = getResponse[1]
        queryLoading.value = false

        if (typeof response === 'string') {
          alert.value = { type: 'error', message: `User: ${snowflakeUser.value}  | Role: ${snowflakeRole.value} : ${response}` }

          return null
        }
        buildTable(response.rows)

        return null
      } catch (err) {
        let errorMessage = err
        // eslint-disable-next-line prefer-destructuring
        if (errorMessage.toString().includes('Snowflake Returned Error: ')) errorMessage = errorMessage.split('Snowflake Returned Error: ')[1]
        alert.value = { type: 'error', message: `${errorMessage} For User: ${snowflakeUser.value}  | Role: ${snowflakeRole.value}.` }
        queryError.value = err
        queryLoading.value = false
      }

      return null
    }

    return {
      snowflakeUser,
      snowflakeRole,
      bindingAuth,
      dataBindings,
      alert,
      selectedConnector,
      selectedTask,
      bindingHeaders,
      validateQuery,
      sqlQuery,
      queryChanged,
      buildTable,
      headers,
      limitEnforced,
      queryError,
      queryResults,
      queryLoading,
      icons: {
        mdiFileTableBox,
      },
    }
  },
}
</script>
<style lang="scss">
.v-data-table-header th {
  white-space: nowrap;
}
</style>
