<template>
  <v-card flat>
    <v-card-text>
      <v-row>
        <v-col>
          <v-alert
            border="left"
            colored-border
            text
            color="primary"
          >
            <span class="font-weight-bold">            <v-icon left>
              {{ bindingIcons[selectedTask.icon] }}
            </v-icon>{{ selectedTask.task }}</span>
          </v-alert>
        </v-col>
      </v-row>
      <component
        :is="bindingView()"
        v-if="selectedTask && selectedTask.id"
        @showSaveButton="value => showSaveButton = value"
        @setEmittedBindingData="setEmittedBindingData"
        @saveBindings="saveBindings"
      >
      <!-- :sql-query="sqlQuery" -->
      </component>
      <v-row>
        <v-col align="end">
          <v-btn
            v-if="showSaveButton"
            :disabled="!bindingsReady"
            color="info"
            @click="saveBindings()"
          >
            Save and Continue
          </v-btn>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>
<script>
import {} from '@mdi/js'

import { inject, ref } from '@vue/composition-api'

import DefaultDispatch from '../binding-views/defaultDispatch.vue'

import GoogleSheets from '../connectors/googleSheets/googleSheetsBindings.vue'

import GoogleSheetsMultipleSheets from '../connectors/googleSheets/GoogleSheetsMultipleSheetsBindings.vue'

import S3 from '../connectors/s3/S3Bindings.vue'

import { bindingIcons } from './BindingCreationScripts'

export default {
  components: {
    DefaultDispatch,
    GoogleSheets,
    GoogleSheetsMultipleSheets,
    S3,
  },
  setup(none, { emit }) {
    const selectedTask = inject('selectedTask')
    const bindingsReady = ref(false)
    const emittedBindingData = inject('emittedBindingData')
    const showSaveButton = ref(true)

    // By default returns default component
    const bindingView = () => {
      if (['GSHEETS01'].includes(selectedTask.value.id)) return 'defaultIngestion'
      if (['GSHEETS02'].includes(selectedTask.value.id)) return 'googleSheets'
      if (['GSHEETS03'].includes(selectedTask.value.id)) return 'googleSheetsMultipleSheets'
      if (['S301'].includes(selectedTask.value.id)) return 's3'

      return 'defaultDispatch'
    }

    const setEmittedBindingData = emittedData => {
      emit('setEmittedBindingData', emittedData)
      bindingsReady.value = emittedData.bindingsReady || false
      emit('setBindingsReady', bindingsReady.value)
    }

    // ——————————————————————————————————————————
    //* ——— Save Binding Data From Emitted Data
    // ——————————————————————————————————————————
    const saveBindings = async bindingDataPayload => {
      const saveBindingDataPayload = bindingDataPayload || emittedBindingData.value
      emit('saveBindings', { bindingData: saveBindingDataPayload })
      emit('proceed', 'schedule-job')
    }

    return {
      showSaveButton,
      emittedBindingData,
      setEmittedBindingData,
      saveBindings,
      bindingsReady,
      bindingView,
      selectedTask,
      bindingIcons,
      icons: {},
    }
  },
}
</script>
