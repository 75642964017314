<template>
  <div>
    <v-card>
      <v-card-title>
        <v-icon
          left
          color="primary"
        >
          {{ icons.mdiCogTransfer }}
        </v-icon><span class="primary--text">{{ isEdit ? 'Edit Snow Binding' : 'Snow Binding Creation' }}</span>
        <div>
          <span class="primary--text ml-2">|</span>
          <strong><span class="primary--text ml-2">{{ snowBindingName }}</span></strong>
        </div>
      </v-card-title>

      <v-tabs
        v-model="tab"
        class="pb-5"
        vertical
      >
        <v-img
          v-if="selectedConnector || bindingData"
          contain
          max-height="200"
          max-width="200"
          class="ma-3"
          :src="selectedConnector ? selectedConnector.avatar : bindingData.avatar"
          :alt="selectedConnector ? selectedConnector.name : bindingData.connectorName"
        >
        </v-img>
        <div
          v-for="component in components"
          :key="component.tab"
        >
          <v-tab
            v-show="component.show"
            :disabled="!component.enabled"
            @click="tab = component.tab"
          >
            <v-icon left>
              {{ component.icon }}
            </v-icon>
            {{ component.name }}
          </v-tab>
        </div>
        <!-- :disabled="(!selectedTask || ((selectedTask && !selectedTask.type === 'egress') && (!bindingsReady || !queryReady)))" -->
        <!-- Select a Connector -->
        <v-tabs-items v-model="tab">
          <v-tab-item
            v-for="component in components"
            :key="component.tab"
          >
            <keep-alive>
              <component
                :is="components[tab].tab"
                @setSelectedConnector="connector => selectedConnector = connector"
                @setSelectedTask="task => selectedTask = task"
                @setSelectedAuthentication="auth => bindingAuth = auth"
                @setDataBindings="bindings => dataBindings = bindings"
                @setTaskProp="taskPropValues => taskProp = taskPropValues"
                @setIngestionSchema="schema => ingestionSchema = schema"
                @setEmittedBindingData="setEmittedBindingData"
                @queryResultsSetter="queryChange"
                @setQueryReady="setQueryReady"
                @saveBindings="saveBindings"
                @proceed="proceed"
              ></component>
            </keep-alive>
          </v-tab-item>
        </v-tabs-items>
      </v-tabs>
    </v-card>
  </div>
</template>

<script>
/* eslint-disable object-curly-newline */
/* eslint-disable operator-linebreak */
/* eslint-disable prefer-destructuring */
/* eslint-disable no-return-assign */
// :disabled="(!selectedTask || ((selectedTask && !selectedTask.type === 'egress') && (!bindingsReady || !queryReady)))"
import store from '@/store'

import { useRouter } from '@core/utils'

import { mdiClockOutline, mdiCogTransfer, mdiDatabaseCheckOutline, mdiTableEdit, mdiTransitConnectionHorizontal, mdiTransitConnectionVariant } from '@mdi/js'

import { computed, onMounted, provide, ref, watch } from '@vue/composition-api'

import BindData from './BindData.vue'

// Components
import BuildModelSql from './BuildModelSql.vue'

import CreateBinding from './CreateBinding.vue'

import ScheduleJob from './ScheduleJob.vue'

import TestBindings from './TestBindings.vue'

import { saveBindingData } from './BindingCreationScripts'

import { cloudFunction } from '@/functions'
import snowbindingConnectorLogos from '../connectors/snowbindingConnectorLogos'

export default {
  components: {
    CreateBinding,
    TestBindings,
    ScheduleJob,
    BuildModelSql,
    BindData,
  },
  setup() {
    // ————————————————————————————————————
    //* ——— SnowBinding Creation Setup
    // ————————————————————————————————————
    // Note that binding data is always staying in sync from Firestore Synced Document - Do not write directly to bindingDocs.value, instead update the document
    // If SnowBindings Store is not currently syncing -> Sync It
    if (!store.state.snowBindings.snowBindingTasks || store.state.snowBindings.snowBindingTasks?.length === 0) store.dispatch('snowBindings/syncSnowBindingTasks', { showInactive: false })
    if (!store.state.snowBindings.snowBindingConnectors || store.state.snowBindings.snowBindingConnectors?.length === 0) store.dispatch('snowBindings/syncSnowBindingConnectors')
    const { route } = useRouter()
    const account = computed(() => store.state.accountProfile)
    provide('account', account)
    const accountSchema = computed(() => `${account.value.snowflakeSettings.ownershipSchemaDatabase}.${account.value.snowflakeSettings.ownershipSchema}`)
    provide('accountSchema', accountSchema)
    const user = computed(() => store.state.user)
    provide('user', user)
    const isEdit = ref(false)
    const setIsEdit = () => (isEdit.value = true)
    provide('isEdit', isEdit)
    const snowBindingName = ref(null)
    provide('snowBindingName', snowBindingName)
    const taskProp = ref(null)
    provide('taskProp', taskProp)
    const emittedBindingData = ref(null)
    provide('emittedBindingData', emittedBindingData)
    const ingestionSchema = ref(null)
    provide('ingestionSchema', ingestionSchema)

    const setEmittedBindingData = data => {
      emittedBindingData.value = data
    }

    // ————————————————————————————————————
    //* ——— Getting Connector Data
    // ————————————————————————————————————
    const selectedConnector = ref(null)
    provide('selectedConnector', selectedConnector)
    const selectedTask = ref(null)
    provide('selectedTask', selectedTask)
    const docId = ref(null)
    provide('docId', docId)
    const bindingData = computed(() => store.state.snowBindings.snowBindingTasks?.filter(f => String(f.id) === String(docId.value))[0] || null) // Synced Binding Data Object From Firestore
    provide('bindingData', bindingData)
    const snowBindingConnectors = computed(() => store.state.snowBindings.snowBindingTemplates)
    const bindingAuth = ref(null)
    provide('bindingAuth', bindingAuth)

    // v-if="currentTabName === 'buildModel' && isEdit ? bindingData : selectedTask"
    // (selectedTask && selectedTask.type === 'ingress' && currentTabName === 'scheduleJob') || (selectedTask && selectedTask.type === 'egress' && currentTabName === 'scheduleJob' && bindingsReady)
    // && queryReady

    // ——————————————————————————————————————————
    //* ——— Snowflake Query Data and Results
    // ——————————————————————————————————————————
    // QueryData includes: queryResults, headers, and sqlQuery
    const queryData = ref({ queryResults: [], headers: [], sqlQuery: null })
    provide('queryData', queryData)

    // Emitted From Query Builder
    const queryChange = queryResult => {
      queryData.value.queryResults = queryResult.queryResults
      queryData.value.headers = queryResult.headers
      queryData.value.sqlQuery = queryResult.query
    }

    // Emitted if Data Bindings and Query are Ready
    const queryReady = ref(false)
    function setQueryReady(value) {
      queryReady.value = value
    }
    const bindingsReady = ref(false)
    function setBindingsReady(value) {
      bindingsReady.value = value
    }

    // ——————————————————————————————————————————————————
    //* ——— Identify All Components
    // ——————————————————————————————————————————————————
    const tab = ref(0)
    const components = computed(() => [
      { name: 'Connector', tab: 'create-binding', icon: mdiTransitConnectionVariant, show: true, enabled: !bindingData.value },
      { name: 'Build Model', tab: 'build-model-sql', icon: mdiTableEdit, show: selectedTask.value?.type === 'egress', enabled: isEdit.value ? !!bindingData.value : !!snowBindingName.value },
      { name: 'Bind Dataset', tab: 'bind-data', icon: mdiTransitConnectionHorizontal, show: selectedTask.value?.type === 'egress', enabled: !!snowBindingName.value && queryReady.value },
      { name: 'Schedule Job', tab: 'schedule-job', icon: mdiClockOutline, show: true, enabled: (selectedTask.value?.type === 'ingress' && !!snowBindingName.value) || (selectedTask.value?.type === 'egress' && queryReady.value) },
      { name: 'Test Bindings', tab: 'test-bindings', icon: mdiDatabaseCheckOutline, show: true, enabled: (selectedTask.value?.type === 'ingress' && !!snowBindingName.value) || (selectedTask.value?.type === 'egress' && queryReady.value) },
    ])
    const proceed = tabName => {
      tab.value = components.value.findIndex(i => i.tab === tabName)
    }

    const connectors = computed(() => {
      const connectorsArray = []
      snowBindingConnectors.value.forEach(conn => {
        const avatar = snowbindingConnectorLogos(conn.id)
        const connector = { ...conn, avatar }

        // Find Auth Entries
        const authentication = store.state.snowBindings.snowBindingConnectors.filter(f => f.appId === conn.id)
        if (authentication && authentication.length > 0) {
          connectorsArray.push({ ...connector, auth: authentication })
        } else {
          connectorsArray.push(connector)
        }
      })

      return connectorsArray
    })
    provide('connectors', connectors)

    const dataBindings = ref([])
    provide('dataBindings', dataBindings)

    // ————————————————————————————————————
    //* ——— If Editing Data
    // ————————————————————————————————————

    async function setEditDataBindings() {
      if (selectedTask?.value.bindingFunction) {
        const { data: bindings } = await cloudFunction({ functionName: selectedTask.value.bindingFunction, payload: { ...selectedTask.value, connector: selectedConnector.value, taskProp: taskProp.value, auth: bindingAuth.value, isBindingCreation: true } })
        if (bindings.status === 'ERROR') throw new Error(bindings.message)
        if (bindings) {
          dataBindings.value = bindings
        }
      }

      // const bindings = await executeBindingFunction({ ...selectedTask.value, account: account.value, user: user.value, connector: selectedConnector.value, taskProp: taskProp.value, auth: bindingAuth.value })
    }

    // Gets Task/Connector Data From Binding TaskId
    watch(bindingData, to => {
      bindingsReady.value = to.bindingsReady
      if (tab.value === 0) {
        if (to.taskType === 'ingress') proceed('schedule-job')
        else proceed('build-model-sql')
      }
      if (!bindingData.value || (bindingData.value && bindingData.value.bindingDataArray ? bindingData.value.bindingDataArray.length === 0 : bindingData.value.length === 0)) return
      bindingAuth.value = store.state.snowBindings.snowBindingConnectors.filter(f => f.connectionId === bindingData.value.connectionId)[0]
      const taskType = bindingData.value.taskType === 'egress' ? 'egressTasks' : 'ingressTasks'
      const connectorsArray = []
      connectors.value.forEach(conn => {
        if (conn[taskType]) {
          conn[taskType].forEach(record => {
            connectorsArray.push(record)
          })
        }
      })
      selectedTask.value = connectorsArray.filter(f => f.id === bindingData.value.taskId)[0]
      selectedTask.value.type = bindingData.value.taskType
      selectedConnector.value = connectors.value.filter(f => f.id === bindingData.value.appId)[0]
      snowBindingName.value = bindingData.value.name
      if (bindingData.value.taskProp) taskProp.value = bindingData.value.taskProp
      setEditDataBindings()
    })

    // ——————————————————————————————————————————
    //* ——— Save Binding Data From Emitted Data
    // ——————————————————————————————————————————
    const saveBindings = async emittedBindingProps => {
      let savePayload = { account: account.value, docId: docId.value, snowBindingName: snowBindingName.value, isEdit: isEdit.value, selectedConnector: selectedConnector.value, selectedTask: selectedTask.value, auth: bindingAuth.value }
      if (emittedBindingProps) savePayload = { ...savePayload, ...emittedBindingProps }
      if (!emittedBindingProps && taskProp?.value?.taskProp) savePayload = { ...savePayload, taskProp: taskProp.value.taskProp }
      if (!savePayload.bindingData && emittedBindingData.value) savePayload.bindingData = emittedBindingData.value
      if (queryData.value?.sqlQuery) savePayload.queryData = queryData.value
      if (ingestionSchema.value) savePayload = { ...savePayload, ...ingestionSchema.value }
      await saveBindingData(savePayload)
      setIsEdit()
    }

    onMounted(async () => {
      if (route.value.name.includes('edit-binding')) {
        docId.value = route.value?.params?.id
        isEdit.value = true
      } else {
        docId.value = Date.now()
      }
    })

    return {
      setEmittedBindingData,
      ingestionSchema,
      saveBindings,
      proceed,
      tab,
      components,
      taskProp,
      docId,
      setIsEdit,
      bindingAuth,
      queryData,
      setQueryReady,
      setBindingsReady,
      queryReady,
      bindingsReady,
      bindingData,
      dataBindings,
      queryChange,
      isEdit,
      selectedTask,
      connectors,
      snowBindingName,
      selectedConnector,

      icons: {
        mdiDatabaseCheckOutline,
        mdiClockOutline,
        mdiCogTransfer,
        mdiTransitConnectionVariant,
        mdiTransitConnectionHorizontal,
        mdiTableEdit,
      },
    }
  },
}
</script>

<style lang="scss" scoped>
.v-data-table {
  white-space: nowrap;
}
.task-selected {
  background-color: orange;
}
</style>
