<template>
  <v-container>
    <v-alert
      text
      class="font-weight-black"
    >
      Authenticate with Google To Read/Write to Google Sheets
    </v-alert>
    <v-row
      class="mt-3"
      justify="center"
    >
      <v-img
        v-if="!authLoading && !refreshToken"
        style="cursor: pointer"
        src="../../../../assets/images//buttons/google-signin-button.png"
        max-width="300"
        @click="authenticateWithGoogle()"
      >
      </v-img>
      <v-btn
        v-if="authLoading"
        large
        :loading="authLoading"
        class="ml-5 mt-3"
        color="info"
      >
        Authenticating
      </v-btn>
      <v-btn
        v-if="!authLoading && refreshToken"
        class="ml-5 mt-3"
        color="info"
      >
        Authenticated!
      </v-btn>
    </v-row>
    <v-row
      v-if="errorMessage"
      justify="center"
      class="mt-5"
    >
      <v-alert type="error">
        {{ errorMessage }}
      </v-alert>
    </v-row>
  </v-container>
</template>

<script>
/* eslint-disable camelcase */
import { cloudFunction } from '@/functions'
import serverConfig from '@serverConfig'
import { ref } from '@vue/composition-api'

export default {
  setup(none, { emit }) {
    // Google Authentication Methods
    const authLoading = ref(false)
    const code = ref(null)
    const scopes = ref(null)
    const refreshToken = ref(null)
    const accessToken = ref(null)
    const googleProfile = ref(null)
    const errorMessage = ref(null)

    //
    //* ——— Save and Write Connection Data To Database ——————————————————
    //
    const saveAuthSelection = async () => {
      try {
        // Write Connection Data To Firebase
        await new Promise(r => setTimeout(r, 1000))
        const connectionName = `Google Account: ${googleProfile.value.email}`
        const payload = { connectionName, appId: 'googleSheets', refreshToken: refreshToken.value, accessToken: accessToken.value, googleProfile: googleProfile.value, scopes: scopes.value }
        emit('write-to-firestore', payload)
      } catch (error) {
        authLoading.value = false
        errorMessage.value = `Failed To Write Google Connection Data To Firebase. ERROR: ${error}`
        console.trace('\u001b[1;31m', errorMessage.value)
        throw errorMessage.value
      }
    }

    //
    //* ——— Get User Data From Google with Tokens —————————
    //

    //
    //* ——— Get Tokens From Received Code ——————————————————
    //
    const getTokens = async () => {
      try {
        const response = await cloudFunction({ functionName: 'googleGetTokens', payload: { code: code.value, redirectURL: serverConfig.externalAuthHandler } })
        const { user, tokens } = response.data
        const { refresh_token, access_token } = tokens

        // If refreshToken is null then app must be revoked before retrying
        if (access_token && !refresh_token) {
          errorMessage.value = `Could not get a refresh token to connect. Please revoke access to the ${serverConfig.name} app and try again. Link: https://myaccount.google.com/permissions`
        }

        refreshToken.value = refresh_token
        accessToken.value = access_token
        googleProfile.value = user
        authLoading.value = false

        if (refreshToken.value) {
          saveAuthSelection()
        }

        return
      } catch (error) {
        authLoading.value = false
        errorMessage.value = ` ERROR: ${error}`
        console.trace('\u001b[1;31m', errorMessage.value)
        throw errorMessage.value
      }
    }

    //
    //* ——— New Browser Opened. Loops Through (seconds) until verified tokens received ——————————————————
    //
    const watchForAuthentication = async () => {
      try {
        setTimeout(() => {
          let tempAuth = localStorage.getItem('tempAuth')
          if (tempAuth && tempAuth !== 'null') {
            tempAuth = JSON.parse(tempAuth)
            code.value = tempAuth.code

            // Parse Scopes Object
            const scopesArray = []
            tempAuth.scope.split(' ').forEach(item => {
              if (item.includes('https')) scopesArray.push(item)
            })
            scopes.value = scopesArray
            getTokens()
          } else {
            watchForAuthentication()
          }
        }, 3000)
      } catch (error) {
        authLoading.value = false
        errorMessage.value = ` ERROR: ${error}`
        console.trace('\u001b[1;31m', errorMessage.value)
        throw errorMessage.value
      }
    }

    //
    //* ——— Generates Google Auth Window URL and Opens New Browser Window For Google Authentication ——————————————————
    // Moves to Next Function Listening For localStorage Changes Containing New Tokens From Google
    //

    const authenticateWithGoogle = async () => {
      try {
        authLoading.value = true

        // Clear Any Previous Errors
        errorMessage.value = null
        console.log('Attempting to Authenticate with Google...')

        // Clear store.state.tempAuth Just In Case
        localStorage.setItem('tempAuth', null)
        const response = await cloudFunction({ functionName: 'googleSheetsOathUrl', payload: { redirectURL: serverConfig.externalAuthHandler } })
        window.open(response.data)
        watchForAuthentication()
      } catch (error) {
        authLoading.value = false
        errorMessage.value = `Failed To Authenticate With Google. ERROR: ${error}`
        console.trace('\u001b[1;31m', errorMessage.value)
        throw errorMessage.value
      }
    }

    return {
      errorMessage,
      refreshToken,
      accessToken,
      authLoading,
      authenticateWithGoogle,
      saveAuthSelection,
    }
  },
}
</script>
