<template>
  <v-container>
    <v-row v-if="selectedConnector.authDocs">
      <v-col>
        <v-alert
          dense
          type="info"
          text
        >
          Need Help Getting Authentication Credentials for {{ selectedConnector.name }}?
          <a
            :href="selectedConnector.authDocs"
            target="_blank"
            class="ml-5 text-decoration-none"
          >
            <v-btn
              dense
              small
              color="info"
            >
              Docs
            </v-btn>
          </a>
        </v-alert>
      </v-col>
    </v-row>
    <v-alert
      text
      class="font-weight-black"
    >
      Enter Your Authentication Credentials for {{ selectedConnector.name }}
    </v-alert>
    <!-- apiEndpoint and tokenEndpoint -->
    <v-row v-if="authProps.apiEndpoint || authProps.tokenEndpoint || authProps.tenantId || authProps.siteId">
      <v-col
        v-if="authProps.apiEndpoint"
        sm="12"
        md="6"
      >
        <v-text-field

          v-model="auth.apiEndpoint"
          outlined
          :label="authProps.apiEndpoint.name || 'API Endpoint'"
          :hint="authProps.apiEndpoint.hint"
          persistent-hint
          :rules="authProps.apiEndpoint.required ? [v => !!v || `${authProps.apiEndpoint.name || 'API Endpoint'} is Required`] : []"
        >
        </v-text-field>
      </v-col>
      <v-col
        v-if="authProps.tokenEndpoint"
        sm="12"
        md="6"
      >
        <v-text-field

          v-model="auth.tokenEndpoint"
          outlined
          :label="authProps.tokenEndpoint.name || 'Token Endpoint'"
          :hint="authProps.tokenEndpoint.hint"
          persistent-hint
          :rules="authProps.tokenEndpoint.required ? [v => !!v || `${authProps.tokenEndpoint.name || 'Token Endpoint'} is Required`] : []"
        >
        </v-text-field>
      </v-col>
      <v-col
        v-if="authProps.tenantId"
        sm="12"
        md="6"
      >
        <v-text-field

          v-model="auth.tenantId"
          outlined
          :label="authProps.tenantId.name || 'Tenant ID'"
          :hint="authProps.tenantId.hint"
          persistent-hint
          :rules="authProps.tenantId.required ? [v => !!v || `${authProps.tenantId.name || 'Tenant ID'} Is Required`] : []"
        >
        </v-text-field>
      </v-col>
      <v-col
        v-if="authProps.siteId"
        sm="12"
        md="6"
      >
        <v-text-field

          v-model="auth.siteId"
          outlined
          :label="authProps.siteId.name || 'Site ID'"
          :hint="authProps.siteId.hint"
          persistent-hint
          :rules="authProps.siteId.required ? [v => !!v || `${authProps.siteId.name || 'Site ID'} Is Required`] : []"
        >
        </v-text-field>
      </v-col>
    </v-row>
    <!-- username, password, clientId, secretId, accessToken, and refreshToken -->
    <v-row v-if="authProps.clientId || authProps.clientSecret || authProps.accessToken || authProps.refreshToken ">
      <v-col
        v-if="authProps.username"
        sm="12"
        md="6"
      >
        <v-text-field

          v-model="auth.username"
          outlined
          :label="authProps.username.name || 'Username'"
          :hint="authProps.username.hint"
          persistent-hint
          :rules="authProps.username.required ? [v => !!v || `${authProps.username.name || 'Username'} is Required`] : []"
        >
        </v-text-field>
      </v-col>
      <v-col
        v-if="authProps.password"
        sm="12"
        md="6"
      >
        <v-text-field
          v-model="auth.password"
          type="password"
          outlined
          :label="authProps.password.name || 'Password'"
          :hint="authProps.password.hint"
          persistent-hint
          :rules="authProps.password.required ? [v => !!v || `${authProps.password.name || 'Password'} is Required`] : []"
        >
        </v-text-field>
      </v-col>
      <v-col
        v-if="authProps.clientId"
        sm="12"
        md="6"
      >
        <v-text-field

          v-model="auth.clientId"
          outlined
          :label="authProps.clientId.name || 'Client ID'"
          :hint="authProps.clientId.hint"
          persistent-hint
          :rules="authProps.clientId.required ? [v => !!v || `${authProps.clientId.name || 'Client ID'} is Required`] : []"
        >
        </v-text-field>
      </v-col>
      <v-col
        v-if="authProps.clientSecret"
        sm="12"
        md="6"
      >
        <v-text-field
          v-model="auth.clientSecret"
          outlined
          type="password"
          :label="authProps.clientSecret.name || 'Client Secret'"
          :hint="authProps.clientSecret.hint"
          persistent-hint
          :rules="authProps.clientSecret.required ? [v => !!v || `${authProps.clientSecret.name || 'Client Secret'} is Required`] : []"
        >
        </v-text-field>
      </v-col>
      <v-col
        v-if="authProps.accessToken"
        sm="12"
        md="6"
      >
        <v-text-field
          v-model="auth.accessToken"
          outlined
          :label="authProps.accessToken.name || 'Access Token'"
          :hint="authProps.accessToken.hint"
          persistent-hint
          :rules="authProps.accessToken.required ? [v => !!v || `${authProps.accessToken.name || 'Access Token'} is Required`] : []"
        >
        </v-text-field>
      </v-col>
      <v-col
        v-if="authProps.refreshToken"
        sm="12"
        md="6"
      >
        <v-text-field
          v-model="auth.refreshToken"
          outlined
          :label="authProps.refreshToken.name || 'Refresh Token'"
          :hint="authProps.refreshToken.hint"
          persistent-hint
          :rules="authProps.refreshToken.required ? [v => !!v || `${authProps.refreshToken.name || 'Refresh Token'} is Required`] : []"
        >
        </v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col sm="12">
        <v-alert
          text
          class="font-weight-black"
        >
          What would you like to name this connection?
        </v-alert>
      </v-col>
      <v-col sm="12">
        <v-text-field
          v-model="auth.connectionName"
          outlined
          :label="`Name For This Connection (i.e. Default ${selectedConnector.name} Connection)`"
          :rules="[v => !!v || 'Connection name is required']"
        >
        </v-text-field>
      </v-col>
      <v-col sm="12">
        <v-alert
          v-if="errorMessage"
          type="error"
        >
          {{ errorMessage }}
        </v-alert>
        <v-btn
          v-if="!validationSuccessful"
          :loading="loading"
          :disabled="!validationButtonEnabled"
          class="info"
          @click="validateCredentials"
        >
          Validate Credentials
        </v-btn>
        <v-alert
          v-if="validationSuccessful"
          type="success"
        >
          Validation Successful
        </v-alert>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { cloudFunction } from '@/functions'

import { computed, inject, ref } from '@vue/composition-api'

export default {
  setup(none, { emit }) {
    // Parent Injections
    // Selected Connector: auth.type, auth.docs
    const selectedConnector = inject('selectedConnector')

    // Authentication Object Variables
    // Created By getAuthProps
    // Possible Values: accessToken, secretToken
    const auth = ref({
      connectionName: null,
    })

    // Finds Authentication Properties to Use
    // Available Options: Oauth: 'apiEndpoint', 'authorizationEndpoint', 'tokenEndpoint', 'clientId', 'clientSecret'
    // Others: 'username', 'password', 'accessToken', 'refreshToken'
    const authProps = computed(() => {
      const propsObject = {}
      const props = selectedConnector.value?.authentication?.props || []
      props.forEach(prop => {
        propsObject[prop.prop] = prop
      })

      return propsObject
    })

    // Authentication UI Variables
    const validationSuccessful = ref(false)
    const loading = ref(false)
    const errorMessage = ref(null)

    const validationButtonEnabled = computed(() => {
      let enabled = true

      let requiredFields = selectedConnector.value?.authentication?.props || []
      requiredFields = requiredFields.filter(f => f.required)
      requiredFields.forEach(field => {
        if (!auth.value[field.prop]) enabled = false
      })
      if (!auth.value.connectionName) enabled = false

      return enabled
    })

    // Authentication Functions
    // NOTE: Validation Functions should be named connectorName + AuthValidation (i.e. - hubspotAuthValidation)
    async function encryptData(string) {
      const response = await cloudFunction({ functionName: 'encryptData', payload: { text: string } })

      return response.data
    }
    const validateCredentials = async () => {
      try {
        errorMessage.value = null
        loading.value = true

        // Encrypt Sensitive Data
        const { accessToken, clientSecret, refreshToken, password, ...authData } = auth.value
        if (accessToken) authData.accessToken = await encryptData(accessToken)
        if (refreshToken) authData.refreshToken = await encryptData(refreshToken)
        if (clientSecret) authData.clientSecret = await encryptData(clientSecret)
        if (password) authData.password = await encryptData(password)

        // Ensure that a cloudFunction exists for the connector Auth Validation: {connectorId}AuthValidation
        await cloudFunction({ functionName: 'snowBindingAuthenticationFunctions', payload: { connector: selectedConnector.value, auth: authData } })

        loading.value = false
        validationSuccessful.value = true

        await new Promise(r => setTimeout(r, 1000))

        emit('write-to-firestore', { ...authData, appId: selectedConnector.value.id, appName: selectedConnector.value.name })
        loading.value = false
      } catch (err) {
        loading.value = false
        if (err.toString().includes('401')) {
          errorMessage.value = 'Authentication Failed. Please Check Your Credentials and Try Again'
        } else {
          errorMessage.value = err.toString()
        }
        throw errorMessage.value
      }
    }

    return {
      // Parent Injections
      selectedConnector,

      // Authentication UI Variables
      auth,
      authProps,
      errorMessage,
      validationSuccessful,
      loading,
      validationButtonEnabled,

      // Authentication Functions
      validateCredentials,
    }
  },
}
</script>
