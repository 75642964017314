<template>
  <div>
    <v-row
      v-if="loading"
      justify="center"
      align="center"
    >
      <div class="text-center">
        <v-progress-circular
          :size="50"
          color="primary"
          indeterminate
        ></v-progress-circular>
      </div>
    </v-row>
    <v-row v-if="!loading">
      <v-col>
        <v-select
          v-model="databaseName"
          :items="databaseList"
          label="Database Name"
          outlined
        ></v-select>
      </v-col>
      <v-col>
        <v-select
          v-model="schemaName"
          :items="schemaList"
          label="Schema Name"
          outlined
        ></v-select>
      </v-col>
      <v-col>
        <v-text-field
          v-model="tableName"
          label="Table Name"
          outlined
        ></v-text-field>
      </v-col>
      <v-col
        sm="1"
        class="mt-3"
        @click="getDatabaseAndSchemas()"
      >
        <v-btn icon>
          <v-icon>{{ icons.mdiRefresh }}</v-icon>
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col align="end">
        <v-btn
          :disabled="!databaseName || !schemaName || !tableName"
          class="primary"
          @click="setSchema"
        >
          Set Schema
        </v-btn>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import { snowflakeQuery } from '@/snowflake'
import { mdiRefresh } from '@mdi/js'
import { computed, onMounted, ref } from '@vue/composition-api'

export default {
  setup(none, { emit }) {
    // For Data Ingestion into Snowflake
    const databaseName = ref(null)
    const databaseSchemaList = ref([])
    const databaseList = computed(() => [...new Set(databaseSchemaList.value.map(m => m.database))])
    const schemaName = ref(null)
    const schemaList = computed(() => {
      const schemas = databaseSchemaList.value.filter(f => f.database === databaseName.value && f.schema !== 'INFORMATION_SCHEMA')

      return schemas.map(m => m.schema)
    })
    const tableName = ref(null)
    const loading = ref(false)

    const getDatabaseAndSchemas = async () => {
      loading.value = true
      const getSchemasQuery = await snowflakeQuery({ query: 'show schemas in account', runOn: 'account' })
      const { rows } = getSchemasQuery
      if (rows && rows.length > 0) {
        databaseSchemaList.value = rows.map(m => ({ database: m.database_name, schema: m.name }))
      }
      loading.value = false
    }

    const setSchema = () => {
      emit('proceed')
      emit('setIngestionSchema', { databaseName: databaseName.value, schemaName: schemaName.value, tableName: tableName.value })
    }

    onMounted(() => {
      getDatabaseAndSchemas()
    })

    return {
      loading,
      getDatabaseAndSchemas,
      setSchema,
      databaseName,
      databaseList,
      schemaName,
      schemaList,
      tableName,
      icons: {
        mdiRefresh,
      },
    }
  },
}
</script>
