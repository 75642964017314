import { cloudFunction } from '@/functions'
import { executeBindingFunction } from '@/functions/snowBindings'

export const googleSheetsObjectSchema = {
  workbookCreationMethod: null,
  googleWorkbookName: null,
  googleWorkbookId: null,
  sheetCreationMethod: null,
  googleSheetsName: null,
  googleSheetId: null,
  googleSheetsFolderId: null,
  googleSheetsSharedWith: null,
  googleSheetsSyncType: null,
  googleSheetsUpsertKey: null,
  googleWorkbookUrl: null,
  cron: null,
  scheduleCreation: null,
  multiSheetFilterColumn: null,
  multiSheetFilterValue: null,
  multiSheetIncludeFilterColumn: true,
  bindingsReady: false,
  paginationLimit: 0,
}

export const googleSheetsGoogleFolderList = async bindingAuth => {
  let googleFolders = null
  const payload = { refreshToken: bindingAuth.refreshToken }
  const response = await cloudFunction({ functionName: 'googleGetFolders', payload })
  let folderList = response.data.map(m => ({ text: m.name, value: m.id }))
  folderList = folderList.sort((a, b) => a.text.localeCompare(b.text))
  googleFolders = folderList

  return googleFolders
}

export const googleSheetsGetWorkbookList = async (bindingData, bindingAuth) => {
  const { workbookCreationMethod } = bindingData
  let error = null
  if (workbookCreationMethod === 'updateWorkbook') {
    // Process Request to text/value
    // Getting Trashed Results As Well As File Could Have Been Trashed
    // Trashed Results Will Only Pull If File Cannot Be Found "UnTrashed"

    // Start With Active Sheets
    const payload = { refreshToken: bindingAuth.refreshToken }
    let activeSheets = await cloudFunction({ functionName: 'googleSheetsList', payload })
    activeSheets = activeSheets.data

    // Check If Current Document (if one) Is In Active Sheets
    // If Not - Then Pull Trashed Sheets
    if (bindingData.googleWorkbookId && !activeSheets.map(m => m.id).includes(bindingData.googleWorkbookId)) {
      payload.includeTrashed = true
      let trashedSheets = await cloudFunction({ functionName: 'googleSheetsList', payload })
      trashedSheets = trashedSheets.data
      const trashedRecord = trashedSheets.filter(f => f.id === bindingData.googleWorkbookId)
      if (!trashedRecord) {
        error = 'Cannot Find Spreadsheet. It must have been permanently deleted.'
      } else {
        activeSheets.push(trashedRecord[0])
      }
    }

    let sheets = activeSheets.map(m => ({ text: m.name, value: m.id }))
    sheets = sheets.sort((a, b) => a.text.localeCompare(b.text))

    return { success: sheets, error }
  }

  return { success: null, sheets: null, error: null }
}

export const googleSheetsGetSheetList = async (bindingData, bindingAuth) => {
  const { sheetCreationMethod, googleWorkbookName } = bindingData
  let sheetsList = null
  if (sheetCreationMethod === 'updateSheet' && googleWorkbookName) {
    const payload = { refreshToken: bindingAuth.refreshToken, workbookId: bindingData.googleWorkbookId }
    let response = await cloudFunction({ functionName: 'googleGetWorkbookMetaData', payload })

    // Process Request to text/value
    const { sheets } = response.data
    if (sheets && sheets.length > 0) {
      response = sheets.map(m => ({ text: m.properties.title, value: m.properties.sheetId }))
      response = response.sort((a, b) => a.text.localeCompare(b.text))
      sheetsList = response
    }
  }

  return sheetsList
}

export const googleSheetsParseSharedWithEmails = (bindingData, toType) => {
  // toType: 'string' or 'array'
  const { googleSheetsSharedWith } = bindingData
  if (!googleSheetsSharedWith) return null
  let emails = null

  if (toType === 'array') {
    if (Array.isArray(googleSheetsSharedWith)) return googleSheetsSharedWith
    emails = googleSheetsSharedWith.replace(/\s/g, '')
    emails = emails.split(',')
  } else {
    if (typeof googleSheetsSharedWith === 'string') return googleSheetsSharedWith
    emails = googleSheetsSharedWith.join(', ')
  }

  return emails
}

export const googleSheetsGetWorkbookSheetNamesFromId = (bindingData, editBindingObject, workbookList, sheetsList, type) => {
  const { workbookCreationMethod, googleWorkbookId, googleWorkbookName, googleSheetId } = bindingData
  const binding = {}

  // Did WorkbookName Change to "CREATED"
  if (type === 'workbook' && workbookCreationMethod === 'created') {
    binding.googleWorkbookId = null
    binding.googleSheetsName = null
    binding.googleSheetId = null

    return {}
  }
  if (type === 'workbook' && editBindingObject && googleWorkbookName !== editBindingObject.googleWorkbookName) {
    binding.sheetCreationMethod = null
    binding.googleSheetsName = null
    binding.googleSheetId = null
  }
  const id = type === 'workbook' ? googleWorkbookId : googleSheetId
  const listName = type === 'workbook' ? workbookList : sheetsList
  let name = null
  // eslint-disable-next-line prefer-destructuring
  name = listName.filter(f => f.value === id)[0]
  if (name && name.text) binding[type === 'workbook' ? 'googleWorkbookName' : 'googleSheetsName'] = name.text

  return binding
}

export const googleSheetsRunGoogleSheetsSync = async (bindingData, account, docId) => {
  const { googleWorkbookName, googleSheetsName } = bindingData
  try {
    console.log(` ******** Running Google Sheets Sync For "${googleWorkbookName}" | "${googleSheetsName || 'Sheet1'}" `)
    const functionPayload = { accountId: account.id, bindingId: docId }

    return await executeBindingFunction(functionPayload)
  } catch (error) {
    console.trace('\u001b[1;31m', error)
    throw error
  }
}
