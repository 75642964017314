<template>
  <v-card flat>
    <v-card-text>
      <v-row>
        <v-col>
          <v-alert
            border="left"
            colored-border
            text
            color="primary"
          >
            <span class="font-weight-bold">
              <v-icon left>
                {{ icons.mdiDatabaseCheckOutline }}
              </v-icon>Save and Test Job</span>
          </v-alert>
        </v-col>
      </v-row>
      <!-- If NOT Limit Results Testing -->
      <!-- ---------------------------- -->
      <v-row v-if="!testLimitedResults">
        <v-col>
          <v-alert type="info">
            <span class="font-weight-bold">Would you like to run this SnowBinding Job now?</span>
            <v-btn
              class="ml-5"
              :loading="loading"
              color="primary"
              @click="executeBinding()"
            >
              Run
            </v-btn>
          </v-alert>
        </v-col>
      </v-row>
      <!-- If IS Limit Results Testing -->
      <!-- ---------------------------- -->
      <template v-if="testLimitedResults">
        <v-row>
          <v-col>
            <v-alert type="info">
              <span class="font-weight-bold">We can attempt to make a single API call with the provided data to ensure that the sync is successful.</span>
            </v-alert>
          </v-col>
        </v-row>
        <v-row
          v-if="testRecords && testRecords.length > 0"
        >
          <v-col sm="10">
            <v-alert type="warning">
              <span class="font-weight-bold">Would you like to make a test API call with the following record(s)?</span>
            </v-alert>
          </v-col>
          <v-col sm="2">
            <v-select
              v-model="numberOfTestRecords"
              :items="[1, 5, 10]"
              label="# of Test Records"
              outlined
            ></v-select>
          </v-col>
        </v-row>
        <template>
          <v-data-table
            dense
            :headers="queryData.headers"
            :items="testRecords"
            :items-per-page="10"
            class="elevation-1"
            hide-default-footer
          ></v-data-table>
        </template>
      </template>
      <!-- UI ERROR/SUCCESS MESSAGES -->
      <!-- ---------------------------- -->
      <v-row class="mt-5">
        <v-col
          v-if="errorMessage"
          sm="12"
        >
          <v-alert type="error">
            {{ errorMessage }}
          </v-alert>
        </v-col>
        <v-col
          v-if="successMessage.message"
          sm="12"
        >
          <v-alert
            text
            type="success"
          >
            <div v-if="successMessage.link">
              <a

                :href="successMessage.link"
                target="_blank"
              >
                {{ successMessage.message }}
              </a>
            </div>
            <div v-else>
              <span>{{ successMessage.message }}</span>
            </div>
          </v-alert>
        </v-col>
      </v-row>
      <v-row class="mt-10">
        <v-col
          :align="'end'"
        >
          <v-btn
            v-if="testLimitedResults"
            class="mr-5"
            color="primary"
            :loading="loading"
            @click="executeBinding()"
          >
            Test Job
          </v-btn>
          <v-btn
            color="success"
            to="/snow-bindings"
          >
            Close
          </v-btn>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>
<script>
import { executeBindingFunction } from '@/functions/snowBindings'

import { mdiDatabaseCheckOutline } from '@mdi/js'

import { computed, inject, onMounted, ref, watch } from '@vue/composition-api'

export default {
  setup() {
    const bindingData = inject('bindingData')
    const queryData = inject('queryData')
    const successMessage = ref({})
    const errorMessage = ref(null)
    const loading = ref(false)
    const testLimitedResults = ref(false)
    const selectedTask = inject('selectedTask')
    const numberOfTestRecords = ref(1)

    const testRecords = computed(() => queryData.value.queryResults.slice(0, numberOfTestRecords.value))

    const executeBinding = () => {
      loading.value = true
      errorMessage.value = null
      successMessage.value = {}
      const bindingExecutionPayload = { bindingId: bindingData.value.id }
      if (testLimitedResults.value) bindingExecutionPayload.json = JSON.stringify(testRecords.value)
      executeBindingFunction(bindingExecutionPayload)
    }

    watch(bindingData, (to, from) => {
      const previousRun = from?.runningStatus?.runCompletedAt || new Date('1970-01-01')
      const currentRun = to?.runningStatus?.runCompletedAt
      if (currentRun > previousRun) {
        loading.value = false
        if (to.runningStatus?.errors && to.runningStatus?.errors.length > 0) {
          errorMessage.value = to.runningStatus?.errors
        } else if (to.runningStatus?.totalsResponse && to.runningStatus.totalsResponse.length > 0) {
          successMessage.value.message = `Job Completed Successfully! The following executions were completed: ${JSON.stringify(to.runningStatus.totalsResponse)}`
        } else if (to.runningStatus.totalProcessedRecordsCount) {
          successMessage.value.message = `Job Completed Successfully! ${to.runningStatus.totalProcessedRecordsCount} events were executed.`
        } else {
          successMessage.value.message = 'Job Completed Successfully!'
        }
      }
    })

    onMounted(() => {
      if (selectedTask.value.testLimitedResults) testLimitedResults.value = true
    })

    return {
      numberOfTestRecords,
      queryData,
      testRecords,
      testLimitedResults,
      selectedTask,
      successMessage,
      errorMessage,
      loading,
      executeBinding,
      icons: {
        mdiDatabaseCheckOutline,
      },
    }
  },
}
</script>
<style lang="scss">
.v-data-table-header th {
  white-space: nowrap;
}
</style>
