<template>
  <div>
    <span>
      <v-icon left>
        {{ icons.mdiFolderMultipleOutline }}
      </v-icon>
      <strong>{{ bindingData.bucketName ? filePathExample : `S3 Bucket Location (${bindingAuth.awsRegion})` }}</strong>
    </span>

    <!-- New or Existing Bucket -->
    <v-container class="ml-3">
      <v-row>
        <v-col sm="12">
          <v-alert
            text
            color="primary"
          >
            Bucket and File Location
          </v-alert>
        </v-col>
        <v-col
          sm="12"
          md="6"
        >
          <v-select
            v-model="bindingData.bucketCreationMethod"
            outlined
            label="New or Existing S3 Bucket"
            :items="[{ text: 'New Bucket', value: 'createBucket' }, { text: 'Existing Bucket', value: 'existingBucket' }]"
            @change="getBucketList()"
            @focus="errorMessage = null"
          ></v-select>
        </v-col>
        <template v-if="bindingData.bucketCreationMethod === 'existingBucket'">
          <v-col
            sm="12"
            md="6"
          >
            <v-select
              v-model="bindingData.bucketName"
              :items="bucketList"
              :loading="loadingFields.bucketNames"
              outlined
              clearable
              label="Bucket Name"
            >
            </v-select>
          </v-col>
        </template>
        <template v-if="bindingData.bucketCreationMethod === 'createBucket'">
          <v-col
            sm="8"
            md="4"
          >
            <v-text-field
              v-model="bindingData.bucketName"
              outlined
              clearable
              label="Bucket Name"
              :rules="[v => !!v || 'Field is required']"
              @focus="errorMessage = null"
            >
            </v-text-field>
          </v-col>
          <v-col
            sm="4"
            md="2"
          >
            <v-btn
              :disabled="!bindingData.bucketName"
              class="mt-3"
              color="primary"
              @click="s3CreateBucket()"
            >
              Create
            </v-btn>
          </v-col>
        </template>
      </v-row>
      <v-row>
        <v-col
          v-if="errorMessage"
          sm="12"
        >
          <v-alert type="error">
            {{ errorMessage }}
          </v-alert>
        </v-col>
      </v-row>
    </v-container>
    <!-- Additional File Params -->
    <template v-if="(bindingData.bucketCreationMethod === 'createBucket' && bucketSuccessfullyCreated) || (bindingData.bucketCreationMethod !== 'createBucket' && bindingData.bucketName)">
      <v-container class="ml-3">
        <v-row>
          <v-col
            sm="12"
            md="4"
          >
            <v-select
              v-model="bindingData.fileExtension"
              :items="fileTypes"
              outlined
              clearable
              label="File Type"
            >
            </v-select>
          </v-col>
          <v-col
            sm="12"
            md="4"
          >
            <v-text-field
              v-model="bindingData.fileName"
              outlined
              clearable
              label="File Name (Do Not Include File Extension)"
              :hint="`File Location: ${filePathExample}`"
              persistent-hint
            >
            </v-text-field>
          </v-col>
          <v-col
            sm="12"
            md="4"
          >
            <v-text-field
              v-model="bindingData.bucketPath"
              outlined
              clearable
              label="Optional Bucket Path"
              hint="i.e. folderPath1/folderPath2"
              persistent-hint
            >
            </v-text-field>
          </v-col>
        </v-row>
        <v-row v-if="bindingData.fileName && bindingData.fileExtension">
          <!-- File Prefix/Suffix -->
          <v-col sm="12">
            <v-alert
              text
              color="primary"
            >
              Optional File Name Prefix/Suffix
            </v-alert>
          </v-col>
          <v-col
            sm="12"
            md="6"
          >
            <v-select
              v-model="bindingData.prefixType"
              :items="prefixSuffixTypes"
              outlined
              label="File Name Prefix"
              :hint="`Example: ${fullFileNameExample}`"
              persistent-hint
            >
            </v-select>
          </v-col>
          <v-col
            sm="12"
            md="6"
          >
            <v-select
              v-model="bindingData.suffixType"
              :items="prefixSuffixTypes"
              outlined
              label="File Name Suffix"
              :hint="`Example: ${fullFileNameExample}`"
              persistent-hint
            >
            </v-select>
          </v-col>
        </v-row>
      </v-container>
    </template>
  </div>
</template>
<script>
import { mdiFolderMultipleOutline } from '@mdi/js'

import { computed, inject, onMounted, ref, watch } from '@vue/composition-api'

import { cloudFunction } from '@/functions'

import { setBindingData } from '../../binding-views/bindingScripts'

import moment from 'moment-timezone'

export default {
  setup(none, { emit }) {
    // ————————————————————————————————————
    //* ——— Parent Injections
    // ————————————————————————————————————
    const isEdit = inject('isEdit')
    const bindingAuth = inject('bindingAuth')

    // ————————————————————————————————————
    //* ——— UI Variables
    // ————————————————————————————————————
    const s3Credentials = computed(() => ({ awsRegion: bindingAuth.value.awsRegion, awsAccessKey: bindingAuth.value.awsAccessKey, awsSecretAccessKey: bindingAuth.value.awsSecretAccessKey }))
    const loadingFields = ref({ bucketNames: false, sheetNames: false })
    const errorMessage = ref(null)

    // ————————————————————————————————————
    //* ——— DropDown Values
    // ————————————————————————————————————
    const bucketList = ref([])
    const fileTypes = [
      { text: 'CSV', value: 'csv' },
      { text: 'JSON', value: 'json' },
      { text: 'Parquet', value: 'parquet' },
    ]
    const prefixSuffixTypes = [
      { text: 'None', value: null },
      { text: 'Date (YYYY-MM-DD)', value: 'date' },
      { text: 'DateTime (YYYY-MM-DD-HH-MM-SS)', value: 'dateTime' },
      { text: 'Unix Timestamp', value: 'unixTimestamp' },
    ]

    // ————————————————————————————————————
    //* ——— BindingData Object
    // ————————————————————————————————————
    const bindingData = ref({
      bucketCreationMethod: null,
      bucketName: null,
      bucketPath: null,
      fileName: null,
      fileExtension: null,
      prefixType: null,
      suffixType: null,
      cron: null,
      scheduleCreation: {},
      bindingsReady: null,
      paginationLimit: 0,
    })

    // When All Bindings Are Ready Emits bindingsReady to Parent
    // Enables the user to Save and Continue the Binding Process
    watch(bindingData, () => {
      const data = bindingData.value
      if (data.bucketCreationMethod && data.bucketName && data.fileName && data.fileExtension) {
        bindingData.value.bindingsReady = true
        emit('setEmittedBindingData', bindingData.value)
        emit('setBindingsReady', true)
      } else {
        bindingData.value.bindingsReady = false
        emit('setBindingsReady', false)
      }
    })

    // ————————————————————————————————————
    //* ——— Binding Functions
    // ————————————————————————————————————
    const prefixExample = computed(() => {
      if (bindingData.value.prefixType === 'date') return moment().format('YYYY-MM-DD')
      if (bindingData.value.prefixType === 'dateTime') return moment().format('YYYY-MM-DD-HH-mm-ss')
      if (bindingData.value.prefixType === 'unixTimestamp') return Date.now()

      return null
    })
    const suffixExample = computed(() => {
      if (bindingData.value.suffixType === 'date') return moment().format('YYYY-MM-DD')
      if (bindingData.value.suffixType === 'dateTime') return moment().format('YYYY-MM-DD-HH-mm-ss')
      if (bindingData.value.suffixType === 'unixTimestamp') return Date.now()

      return null
    })
    const fullFileNameExample = computed(() => `${`${prefixExample.value ? `${prefixExample.value}_` : ''}` || ''}${bindingData.value.fileName}${`${suffixExample.value ? `_${suffixExample.value}` : ''}` || ''}.${bindingData.value.fileExtension}`)
    const filePathExample = computed(() => {
      if (bindingData.value.fileName) {
        return `S3://${bindingData.value.bucketName}/${bindingData.value.bucketPath ? `${bindingData.value.bucketPath}/` : ''}${fullFileNameExample.value}`
      }

      return bindingData.value.bucketName
    })

    const getBucketList = async (force = false) => {
      const { bucketCreationMethod } = bindingData.value
      if (bucketCreationMethod === 'existingBucket' || force) {
        loadingFields.value.bucketNames = true

        // Get Bucket List
        const buckets = await cloudFunction({ functionName: 's3AuthenticationValidation', payload: s3Credentials.value })
        bucketList.value = buckets.data.map(m => m.Name)
        loadingFields.value.bucketNames = false
      }
    }

    // ———————————————————————————————————————————————————————
    //* ——— Sync In Data From Binding Edit Object - If Edit
    // ———————————————————————————————————————————————————————
    const editBindingObject = inject('bindingData')
    watch(editBindingObject, () => {
      bindingData.value = { ...bindingData.value, ...setBindingData(bindingData.value, editBindingObject.value) }
    })

    // —————————————————————————————————————————————————————————
    //* ——— Binding Initiation - Sets Binding Object if Edit
    // —————————————————————————————————————————————————————————
    onMounted(async () => {
      await getBucketList(true)
      if (isEdit.value) {
        bindingData.value = { ...bindingData.value, ...setBindingData(bindingData.value, editBindingObject.value) }
      }
    })

    return {
      // Binding Data
      bindingData,
      bindingAuth,

      // UI Variables
      loadingFields,
      errorMessage,

      // DropDown Items
      bucketList,
      fileTypes,
      prefixSuffixTypes,

      // Binding Functions
      fullFileNameExample,
      filePathExample,
      getBucketList,

      icons: {
        mdiFolderMultipleOutline,
      },
    }
  },
}
</script>
