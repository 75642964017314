<template>
  <v-container>
    <v-alert
      text
      class="font-weight-black"
    >
      Enter Your AWS Access Key
    </v-alert>
    <v-row class="mb-5">
      <v-col sm="12">
        <v-text-field
          v-model="s3Region"
          outlined
          label="AWS Region (i.e. us-east-1)"
        >
        </v-text-field>
        <v-text-field
          v-model="s3AccessKey"
          outlined
          label="Access Key ID"
        >
        </v-text-field>
        <v-text-field
          v-model="s3SecretAccessKey"
          outlined
          label="Secret Access Key"
        >
        </v-text-field>
        <v-text-field
          v-model="s3ConnectionName"
          outlined
          label="Name For This Connection (i.e. Default AWS Connection)"
          :rules="[v => !!v || 'Connection name is required']"
        >
        </v-text-field>
        <v-alert type="info">
          <span>Need Help Getting AWS Credentials?</span>
          <v-btn
            small
            class="ml-5"
            color="info"
            href="https://docs.aws.amazon.com/sdk-for-javascript/v3/developer-guide/getting-your-credentials.html"
            target="_blank"
          >
            Click For Help
          </v-btn>
        </v-alert>
      </v-col>
      <v-col sm="12">
        <v-alert
          v-if="errorMessage"
          type="error"
        >
          {{ errorMessage }}
        </v-alert>
        <v-btn
          v-if="!validationSuccessful"
          :loading="buttonLoading"
          :disabled="!s3Region || !s3AccessKey || !s3SecretAccessKey || !s3ConnectionName"
          class="info"
          @click="s3AuthValidation"
        >
          Validate Access Key
        </v-btn>
        <v-alert
          v-if="validationSuccessful"
          type="success"
        >
          Validation Successful
        </v-alert>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { cloudFunction } from '@/functions'
import { ref } from '@vue/composition-api'

export default {
  setup(none, { emit }) {
    const s3Region = ref(null)
    const s3AccessKey = ref(null)
    const s3SecretAccessKey = ref(null)
    const s3ConnectionName = ref(null)
    const validationSuccessful = ref(false)
    const buttonLoading = ref(false)
    const errorMessage = ref(null)
    const s3AuthValidation = async () => {
      try {
        errorMessage.value = null
        buttonLoading.value = true

        // Encrypt token
        const encryptedAccessKey = await cloudFunction({ functionName: 'encryptData', payload: { text: s3AccessKey.value } })
        const encryptedSecretAccessKey = await cloudFunction({ functionName: 'encryptData', payload: { text: s3SecretAccessKey.value } })

        const s3Credentials = { awsRegion: s3Region.value, awsAccessKey: encryptedAccessKey.data, awsSecretAccessKey: encryptedSecretAccessKey.data }

        await cloudFunction({ functionName: 's3AuthenticationValidation', payload: s3Credentials })
        buttonLoading.value = false
        validationSuccessful.value = true

        await new Promise(r => setTimeout(r, 1000))
        emit('write-to-firestore', { ...s3Credentials, connectionName: s3ConnectionName.value, appId: 's3', appName: 'AWS S3' })
        buttonLoading.value = false
      } catch (err) {
        buttonLoading.value = false
        if (err.toString().includes('401')) {
          errorMessage.value = 'Authentication Failed. Please Check Your Credentials and Try Again'
        } else {
          errorMessage.value = `Failed to Authenticate with AWS: ${err.toString()}`
        }
        throw errorMessage.value
      }
    }

    return {
      errorMessage,
      s3ConnectionName,
      validationSuccessful,
      buttonLoading,
      s3AuthValidation,
      s3Region,
      s3AccessKey,
      s3SecretAccessKey,
    }
  },
}
</script>
