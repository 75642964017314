<template>
  <v-container>
    <v-alert
      type="info"
      text
    >
      Hubspot Developer Account
      <v-btn
        small
        text
        light
      >
        More Info
      </v-btn>
    </v-alert>
    <v-alert
      text
    >
      The best way to start building integrations with HubSpot is to create a <strong>developer account</strong>.
      There are several reasons for this: A developer account is where you create HubSpot apps, each authenticated with OAuth and provided with a configurable set of features and permissions.
      You can also use your developer account to create test accounts, monitor app status and performance, or publish apps to the HubSpot App Marketplace.
      <br><br>
      <a
        href="https://app.hubspot.com/signup-hubspot/developers?hubs_signup-url=developers.hubspot.com%2Fget-started&hubs_signup-cta=developers-getstarted-app&_ga=2.156380860.664758826.1654794028-1038551283.1654565482&step=landing_page"
        target="_blank"
        class="text-decoration-none"
      >
        <v-btn
          class="mt-3"
        >
          Creating a Hubspot Developer Account
        </v-btn>
      </a>
    </v-alert>
    <v-alert
      type="info"
      text
    >
      We strongly recommend testing your data integration with a test account before running in production.
      <br><br>
      <a
        href="https://developers.hubspot.com/docs/api/creating-test-accounts"
        target="_blank"
        class="text-decoration-none"
      >
        <v-btn
          class="mt-3"
          color="info"
        >
          Information On Creating Test Accounts
        </v-btn>
      </a>
    </v-alert>
    <v-alert
      text
      class="mt-10 font-weight-black"
    >
      Enter Your Hubspot Developer Account Access Token
    </v-alert>
    <v-row class="mb-5">
      <v-col sm="12">
        <v-text-field
          v-model="hubspotAccessToken"
          outlined
          label="Access Token"
        >
        </v-text-field>
        <v-text-field
          v-model="hubspotConnectionName"
          outlined
          label="Name For This Connection (i.e. Default HubSpot Connection)"
          :rules="[v => !!v || 'Connection name is required']"
        >
        </v-text-field>
      </v-col>
      <v-col sm="12">
        <v-alert
          v-if="errorMessage"
          type="error"
        >
          {{ errorMessage }}
        </v-alert>
        <v-btn
          v-if="!validationSuccessful"
          :loading="buttonLoading"
          :disabled="!hubspotAccessToken || !hubspotConnectionName"
          class="info"
          @click="hubspotAuthValidation"
        >
          Validate Access Token
        </v-btn>
        <v-alert
          v-if="validationSuccessful"
          type="success"
        >
          Validation Successful
        </v-alert>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { cloudFunction } from '@/functions'
import { ref } from '@vue/composition-api'

export default {
  setup(none, { emit }) {
    const hubspotAccessToken = ref(null)
    const hubspotConnectionName = ref(null)
    const validationSuccessful = ref(false)
    const buttonLoading = ref(false)
    const errorMessage = ref(null)
    const hubspotAuthValidation = async () => {
      try {
        errorMessage.value = null
        buttonLoading.value = true
        await cloudFunction({ functionName: 'hubspotAuthenticationValidation', payload: { validationToken: hubspotAccessToken.value } })
        buttonLoading.value = false
        validationSuccessful.value = true

        await new Promise(r => setTimeout(r, 1000))

        // Encrypt token
        const encryptionResponse = await cloudFunction({ functionName: 'encryptData', payload: { text: hubspotAccessToken.value } })
        emit('write-to-firestore', { accessToken: encryptionResponse.data, connectionName: hubspotConnectionName.value, appId: 'hubspot', appName: 'Hubspot' })
        buttonLoading.value = false
      } catch (err) {
        buttonLoading.value = false
        if (err.toString().includes('401')) {
          errorMessage.value = 'Authentication Failed. Please Check Your Credentials and Try Again'
        } else {
          errorMessage.value = `Failed to Authenticate with Hubspot: ${err.toString()}`
        }
        throw errorMessage.value
      }
    }

    return {
      errorMessage,
      hubspotConnectionName,
      validationSuccessful,
      buttonLoading,
      hubspotAuthValidation,
      hubspotAccessToken,
    }
  },
}
</script>
