import { updateBindingDoc, writeDoc } from '@/firestore'
import { hubspotGetAssociations, hubspotGetObjectProperties } from '../connectors/hubspot/hubspotBindings'

// —————————————————————————————————————————————————————————————————————
//* ——— Binding Creation Icons - Required For All Binding Creations
// —————————————————————————————————————————————————————————————————————
import { mdiAccessPoint, mdiAccountMultipleOutline, mdiAccountOutline, mdiCardAccountDetailsOutline, mdiCheckboxMarkedCircleOutline, mdiCloseCircleOutline, mdiDatabaseArrowLeftOutline, mdiDomain, mdiFileTableBox, mdiFinance, mdiFolderMultipleOutline, mdiKeyVariant, mdiLinkOff, mdiLockOutline, mdiPhoneInTalkOutline, mdiShieldLockOutline, mdiSquareEditOutline, mdiTableLargePlus, mdiTextBoxMultipleOutline } from '@mdi/js'

export const bindingIcons = { mdiFinance, mdiLinkOff, mdiAccessPoint, mdiFolderMultipleOutline, mdiTableLargePlus, mdiTextBoxMultipleOutline, mdiCardAccountDetailsOutline, mdiPhoneInTalkOutline, mdiDomain, mdiAccountMultipleOutline, mdiKeyVariant, mdiShieldLockOutline, mdiCloseCircleOutline, mdiAccountOutline, mdiLockOutline, mdiFileTableBox, mdiCheckboxMarkedCircleOutline, mdiDatabaseArrowLeftOutline, mdiSquareEditOutline }

// ——————————————————————————————————————————
//* ——— PRIMARY BINDING EXECUTION FUNCTION
// ——————————————————————————————————————————

export async function executeBindingFunction(payload) {
  const { bindingFunction } = payload
  if (bindingFunction === 'hubspotGetObjectProperties') return hubspotGetObjectProperties(payload)

  return null
}

// ———————————————————————————————
//* ——— Association Functions
// ———————————————————————————————
// Payload: { associationType, connector, task, auth }
export async function executeAssociationFunction(payload) {
  const { task } = payload
  const associationsFunction = task.associations.function
  if (associationsFunction === 'hubspotGetAssociations') return hubspotGetAssociations(payload)

  return null
}

// ———————————————————————————————————————————————————————
//* ——— Writing Initial SnowBinding and Events Documents
// ———————————————————————————————————————————————————————

export const initialSnowBindingDocWrites = async payload => {
  try {
    const { account, docId, data } = payload

    // Write SnowBinding Doc
    await writeDoc({ account: account.value, collection: 'snowBindings', docId: 'connectors', subCollection: 'bindings', subCollectionDocId: docId, data })

    // Write SnowBinding Events Doc
    // const month = moment(createdAt).startOf('month').format('YYYYMM')
    // const bindingEventsDocId = `${month}_${docId}`
    // const bindingEventsData = { createdAt, month, bindingId: docId, status: null, enabled: true }
    // await writeDoc({ account: account.value, collection: 'snowBindingEvents', docId: bindingEventsDocId, data: bindingEventsData })
  } catch (error) {
    const errorMessage = `Failed To Write Initial SnowBinding and Events Docs. ERROR: ${error}`
    console.trace('\u001b[1;31m', errorMessage)
    throw errorMessage
  }
}

// ————————————————————————————————————————
//* ——— Save Binding Data To Binding Docs
// ————————————————————————————————————————
// Be sure to pass at minimum:
// account, docId, snowBindingName, isEdit: If New or Update to Data
// bindingData: bindingKeys [If Applicable], bindings [If Applicable], associations [If Applicable], taskProp [If Applicable], continueOnError [If Applicable], isBulkLoad [If Applicable], loadMethod [If Applicable]
// queryData: pass the queryData object
// selectedConnector, selectedTask, & auth
export const saveBindingData = async payload => {
  try {
    const { account, docId, snowBindingName, isEdit = false, bindingData, queryData, selectedConnector, selectedTask, auth, continueOnError = true, isBulkLoad = true, loadMethod = 'overwrite', databaseName, schemaName, tableName, cron, scheduleCreation, enabled = false, taskProp } = payload
    let data = { ...bindingData, enabled, continueOnError, isBulkLoad, loadMethod, modifiedAt: new Date() }
    if (bindingData) data = { ...data, ...bindingData }
    if (queryData?.sqlQuery) data.sqlQuery = queryData.sqlQuery
    if (databaseName && schemaName && tableName) data = { ...data, databaseName, schemaName, tableName }
    if (cron) data.cron = cron
    if (scheduleCreation) data.scheduleCreation = scheduleCreation
    if (taskProp) data.taskProp = taskProp
    if (!isEdit) {
      const connectionData = { appId: selectedConnector.id, appName: selectedConnector.name, connectionId: auth.connectionId, taskId: selectedTask.id, taskType: selectedTask.type, continueOnError, isBulkLoad, loadMethod }
      const defaultValues = { createdAt: new Date(), modifiedAt: new Date(), enabled: false, isDeleted: false }
      data = { ...data, ...connectionData, ...defaultValues, account: account.id, id: docId, name: snowBindingName }
      console.log('Writing', data)
      await writeDoc({ account, collection: 'snowBindings', docId: 'connectors', subCollection: 'bindings', subCollectionDocId: String(docId), data })
    } else {
      // If Update
      console.log('Updating', data)
      await updateBindingDoc({ account, docId, data, modifiedAt: new Date() })
    }

    return
  } catch (error) {
    const errorMessage = `Failed To Save Binding Data. ERROR: ${error}`
    console.trace('\u001b[1;31m', errorMessage)
    throw errorMessage
  }
}
