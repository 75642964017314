<template>
  <v-card flat>
    <v-card-text>
      <v-row>
        <v-col>
          <v-alert
            border="left"
            colored-border
            text
            color="primary"
          >
            <span class="font-weight-bold">
              <v-icon left>
                {{ icons.mdiClockOutline }}
              </v-icon>Schedule Job</span>
          </v-alert>
        </v-col>
      </v-row>
      <v-row>
        <!-- Select Schedule DropDown -->
        <!-- ------------------------ -->
        <v-col
          sm="12"
          md="3"
        >
          <v-select
            v-model="selectedSchedule"
            label="Select a Schedule"
            outlined
            :items="scheduleItems"
          ></v-select>
        </v-col>
        <!-- After Completion of Previous Job -->
        <!-- ----------- -->
        <template v-if="selectedSchedule === 'afterJob'">
          <v-col
            sm="8"
          >
            <v-select
              v-model="afterJob"
              :items="afterJobList"
              label="Select a Job"
              outlined
              hint="Execution of Job Will Start AFTER The Completion Of The Job Above"
              persistent-hint
            ></v-select>
          </v-col>
        </template>
        <!-- Custom Cron -->
        <!-- ----------- -->
        <template v-if="selectedSchedule === 'customCron'">
          <v-col
            sm="8"
            md="3"
          >
            <v-text-field
              v-model="cronValue"
              label="Enter a Cron Expression"
              outlined
              hint="Expression Example: 30 4 * * SUN (Every Sunday Morning at 4:30)"
              persistent-hint
            ></v-text-field>
          </v-col>
        </template>
        <!-- What Day -->
        <!-- --------- -->
        <v-col
          v-if="selectedSchedule === 'week'"
          sm="6"
          md="3"
        >
          <v-select
            v-model="daysOfWeek"
            label="Days of The Week"
            outlined
            :items="daysOfWeekItems"
            multiple
          ></v-select>
        </v-col>
        <!-- What Time -->
        <!-- --------- -->
        <v-col
          v-if="['day', 'week'].includes(selectedSchedule)"
          sm="6"
          md="3"
        >
          <v-dialog
            ref="dialog"
            v-model="showTimeDialog"
            :return-value.sync="time"
            persistent
            width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="time"
                label="Select a Time"
                prepend-icon="mdi-clock-time-four-outline"
                readonly
                outlined
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-time-picker
              v-if="showTimeDialog"
              v-model="time"
              full-width
            >
              <v-spacer></v-spacer>
              <v-btn
                text
                color="primary"
                @click="showTimeDialog = false"
              >
                Cancel
              </v-btn>
              <v-btn
                text
                color="primary"
                @click="$refs.dialog.save(time)"
              >
                OK
              </v-btn>
            </v-time-picker>
          </v-dialog>
        </v-col>
        <!-- Timezone -->
        <!-- --------- -->
        <v-col
          v-if="['customCron', 'week', 'day'].includes(selectedSchedule)"
          sm="6"
          md="3"
        >
          <v-select
            v-model="selectedTimeZone"
            label="TimeZone"
            outlined
            :items="timeZoneItems"
          ></v-select>
        </v-col>
        <!-- Cron Apply Button -->
        <!-- ----------------- -->
        <v-col
          v-if="selectedSchedule === 'customCron'"
          class="mt-4"
          sm="4"
          md="3"
        >
          <v-btn
            color="info"
            :disabled="selectedSchedule === 'customCron' && !cronValue"
            @click="applyCron()"
          >
            Apply
          </v-btn>
        </v-col>
      </v-row>
      <!-- Cron Human Readable -->
      <!-- ------------------- -->
      <v-row>
        <v-col sm="12">
          <v-alert :type="cronConversion ? 'info' : 'warning'">
            {{ cronConversion ? cronConversion === 'afterJob' ? `After Previous Job Completion: ${afterJobText || '[ Select Job Above ]'}` : cronReadable : 'Disabled: Will Not Run on a Schedule' }}
          </v-alert>
        </v-col>
      </v-row>
      <v-row class="mt-5">
        <v-col align="end">
          <v-btn
            :loading="loading"
            :disabled="(selectedSchedule === 'customCron' && !cronExpression) || (selectedSchedule === 'afterJob' && !afterJob)"
            color="primary"
            @click="saveSchedulingData"
          >
            Save and Continue
          </v-btn>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>
<script>
import { timeZoneList } from '@/functions/dates'
import { setAfterJobSchedule, snowBindingSchedule } from '@/functions/snowBindings'
import store from '@/store'
import { mdiClockOutline } from '@mdi/js'
import { computed, inject, onMounted, ref } from '@vue/composition-api'
import cronstrue from 'cronstrue'

export default {
  setup(none, { emit }) {
    const account = inject('account')
    const selectedTimeZone = ref(JSON.parse(localStorage.getItem('timeZone'))?.name)
    const timeZoneItems = computed(() => timeZoneList.filter(f => f.value !== 'UseLocalTime'))
    const docId = inject('docId')
    const loading = ref(false)
    const selectedTask = inject('selectedTask')
    const selectedConnector = inject('selectedConnector')
    const bindingData = inject('bindingData')
    const selectedSchedule = ref(null)
    const scheduleItems = [
      { text: 'Disabled', value: null },
      { text: 'After Completion of Previous Job', value: 'afterJob' },
      { text: 'Every 10 Minutes', value: '*/10 * * * *' },
      { text: 'Every 30 Minutes', value: '*/30 * * * *' },
      { text: 'Every 60 Minutes', value: '0 */1 * * *' },
      { text: 'Once a Day', value: 'day' },
      { text: 'Days of the Week', value: 'week' },
      { text: 'Custom Cron Expression', value: 'customCron' },
    ]
    const daysOfWeekItems = [
      { text: 'Sunday', value: 'SUN' },
      { text: 'Monday', value: 'MON' },
      { text: 'Tuesday', value: 'TUE' },
      { text: 'Wednesday', value: 'WED' },
      { text: 'Thursday', value: 'THU' },
      { text: 'Friday', value: 'FRI' },
      { text: 'Saturday', value: 'SAT' },
    ]
    const afterJob = ref(null)
    const afterJobList = computed(() => store.state.snowBindings.snowBindingTasks.filter(f => f.id !== docId.value).map(m => ({ text: m.name, value: m.id })))
    const afterJobText = computed(() => afterJobList.value.filter(f => f.value === afterJob.value)[0]?.text)
    const cronExpression = ref(null)
    const cronValue = ref(null)
    const daysOfWeek = ref([])
    const time = ref('00:00')
    const showTimeDialog = ref(false)

    const cronConversion = computed(() => {
      let cron = null
      if (selectedSchedule.value === 'afterJob') cron = 'afterJob'
      else if (!selectedSchedule.value) cron = null
      else if (selectedSchedule.value === 'customCron') cron = cronExpression.value
      else if (['day', 'week'].includes(selectedSchedule.value)) {
        if (!time.value) time.value = '00:00'
        const timeSplit = time.value.split(':')
        const hour = timeSplit[0]
        const minute = timeSplit[1]
        const days = daysOfWeek.value && daysOfWeek.value.length > 0 ? daysOfWeek.value.join(',') : '*'
        cron = `${minute} ${hour} * * ${days}`
      } else cron = selectedSchedule.value

      return cron
    })

    // Human Readable Cron Description
    const cronReadable = computed(() => (cronConversion.value && cronConversion.value !== 'afterJob' ? `Runs ${cronstrue.toString(cronConversion.value || '* * * * *')}` : null))

    // An Apply Button is Used To Avoid Errors While Typing In the Cron Value
    const applyCron = () => {
      cronExpression.value = cronValue.value
    }

    // ————————————————————————————————————
    //* ——— Saving Schedule Data
    // ————————————————————————————————————
    const lastAfterJobId = ref(null)
    const lastCloudCronValue = ref(null)
    const saveSchedulingData = async () => {
      try {
        loading.value = true
        const isAfterJob = selectedSchedule.value === 'afterJob'
        const cron = cronConversion.value

        //
        //* ——— Build Binding Object To Save ——————————————————
        //
        const scheduleCreation = { cron: cronConversion.value, selectedSchedule: selectedSchedule.value }
        if (isAfterJob) {
          scheduleCreation.afterJob = afterJob.value
        } else {
          if (daysOfWeek.value) scheduleCreation.daysOfWeek = daysOfWeek.value
          if (time.value) scheduleCreation.daysOfWeek = time.value
          if (selectedTimeZone.value) scheduleCreation.timeZone = selectedTimeZone.value
        }

        // const data = { cron: cronConversion.value, scheduleCreation }

        //
        //* ——— Build CRON Job on Google Cloud Scheduler ——————————————————
        // Will Create, Update, Delete based on Cron value
        if (lastCloudCronValue.value || !isAfterJob) {
          if (!lastCloudCronValue.value && !cron) emit('saveBindings')
          else if (lastCloudCronValue !== cron) {
            const schedulePayload = { account: account.value, bindingId: bindingData.value?.id, bindingName: bindingData.value?.name, task: selectedTask.value, connector: selectedConnector.value, cron: isAfterJob ? null : cron, timeZone: selectedTimeZone.value || 'America/New_York' }
            await snowBindingSchedule(schedulePayload)
            lastCloudCronValue.value = cron

            // Save Binding Data
            let enabled = true
            if (!cron) enabled = false
            const data = { scheduleCreation, enabled, cron: scheduleCreation.cron }
            emit('saveBindings', data)
          }
        }

        //
        //* ——— Save "After Job" Scheduling ——————————————————
        //
        // If AfterJobs are the Same Do Nothing
        if (lastAfterJobId.value || isAfterJob) {
          // if (lastAfterJobId.value === scheduleCreation.afterJob) return

          // Set/Remove AfterJobs Array on Both Target Task and Parent Task
          const lastAfterJobTask = lastAfterJobId.value ? store.state.snowBindings.snowBindingTasks.filter(f => String(f.id) === String(lastAfterJobId.value))[0] : null
          const newAfterJobTask = scheduleCreation.afterJob ? store.state.snowBindings.snowBindingTasks.filter(f => String(f.id) === String(scheduleCreation.afterJob))[0] : null

          // Update Task Binding Record and afterJob Schedule Entries
          await setAfterJobSchedule({ account: account.value, bindingId: bindingData.value?.id, cron, lastAfterJobTask, newAfterJobTask, newCloudSchedule: scheduleCreation })
          lastAfterJobId.value = scheduleCreation.afterJob
        }
        loading.value = false
        emit('proceed', 'test-bindings')
      } catch (error) {
        loading.value = false
        const errorMessage = `Failed Saving Scheduling Data. ERROR: ${error}`
        console.trace('\u001b[1;31m', errorMessage)
        throw errorMessage
      }
    }

    const setScheduleData = () => {
      const editProps = bindingData.value?.scheduleCreation
      if (editProps && editProps.cron) {
        selectedSchedule.value = editProps.selectedSchedule
        daysOfWeek.value = editProps.daysOfWeek
        time.value = editProps.time
        cronExpression.value = editProps.cron
        selectedTimeZone.value = editProps.timeZone
        if (editProps.cron && editProps.cron !== 'afterJob') lastCloudCronValue.value = editProps.cron

        // const storePayload = { lastCronValue: editProps.cron }
        if (editProps.cron === 'afterJob') {
          selectedSchedule.value = 'afterJob'

          // storePayload.lastAfterJob = editProps.afterJob
          afterJob.value = editProps.afterJob
          lastAfterJobId.value = editProps.afterJob
        }

        // store.commit('snowBindings/setSnowBindingCron', storePayload)
      }
    }

    onMounted(() => {
      setScheduleData()
    })

    return {
      loading,
      lastAfterJobId,
      lastCloudCronValue,
      selectedTask,
      selectedConnector,
      saveSchedulingData,
      afterJobText,
      afterJobList,
      afterJob,
      cronReadable,
      selectedTimeZone,
      timeZoneItems,
      applyCron,
      cronConversion,
      cronExpression,
      time,
      showTimeDialog,
      daysOfWeekItems,
      daysOfWeek,
      cronValue,
      scheduleItems,
      selectedSchedule,
      icons: {
        mdiClockOutline,
      },
    }
  },
}
</script>
