import { cloudFunction } from '@/functions'

// ————————————————————————————————————
//* ——— HUBSPOT BINDING SCRIPTS
// ————————————————————————————————————
// Requires objectType (i.e. companies, contacts, deals, custom, etc. )
export async function hubspotGetObjectProperties(payload) {
  const { account, objectType, connector, taskProp, auth } = payload
  const response = await cloudFunction({
    functionName: 'hubspotGetObjectProperties',
    payload: {
      isBindingCreation: true, objectType, account, connector, taskProp, auth,
    },
  })

  return response.data
}

export async function hubspotGetCustomObjects(payload) {
  try {
    const { account, connector } = payload
    let response = await cloudFunction({ functionName: 'hubspotGetSchemas', payload: { account, connector, type: 'schemas' } })
    console.log('Response', response)
    if (response.data.statusCode !== 200) {
      const error = 'Check The Private Apps Permissions To HubSpot Schemas'
      throw error
    }
    response = response.data.response
    if (!response || !response.results || response.results.length < 1) return null
    response = response.results
    response = response.filter(f => !f.archived)
    const taskPropList = response.map(m => m.name)

    return {
      taskPropList, taskPropText: 'Select HubSpot Custom Object', taskPropType: 'select', results: response,
    }
  } catch (error) {
    const errorMessage = `Failed to get HubSpot Custom Objects. ERROR: ${error}`
    console.trace('\u001b[1;31m', errorMessage)
    throw errorMessage
  }
}

export async function hubspotGetAssociations(payload) {
  try {
    const { associationType, task, connector, auth, taskProp } = payload
    const objectType = task.objectType === 'custom' ? taskProp : task.objectType
    const properties = await cloudFunction({ functionName: 'hubspotGetObjectProperties', payload: { objectType: associationType, connector, auth } })
    console.log('properties', properties)
    let destBindings = properties.data

    // Get Association Schema Labels
    let associationLabels = await cloudFunction({ functionName: 'hubspotGetSchemas', payload: { objectType, toObjectType: associationType, connector, type: 'associations', auth } })
    console.log('associationLabels', associationLabels)
    associationLabels = associationLabels?.data?.response?.results?.filter(f => f.label)
    associationLabels = associationLabels?.map(m => ({ text: m.label, value: m }))

    // If Looking For Unique Values
    if (task.forceUniqueKeyOnBind) {
      destBindings = destBindings.filter(f => f.hasUniqueValue)
    }

    destBindings = destBindings.map(m => m[task.keys.name])
    const addSourceKey = [{ text: `*** ${connector.name} Primary Key ***`, value: '**sourceKey**' }]
    destBindings = [...addSourceKey, ...destBindings]

    return { associationLabelList: associationLabels, associationDestinationBindingList: destBindings }
  } catch (error) {
    const errorMessage = `Failed Getting HubSpot Associations. ERROR: ${error}`
    console.trace('\u001b[1;31m', errorMessage)
    throw errorMessage
  }
}
