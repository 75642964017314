/* eslint-disable import/prefer-default-export */
// ———————————————————————————————————————————
//* ——— Helper for Setting Binding View Data
// ———————————————————————————————————————————
export const setBindingData = (bindDataViewData, editBindingData) => {
  console.log('Setting Bindings')
  const dataObject = {}
  Object.keys(bindDataViewData).forEach(key => {
    if (editBindingData[key]) dataObject[key] = editBindingData[key]
  })

  return dataObject
}
