<template>
  <div
    v-if="targetBindings.length > 0"
    class="ml-5 mr-5 mb-5"
  >
    <v-expansion-panels v-model="activePanels">
      <v-expansion-panel>
        <v-expansion-panel-header>
          <span>
            <v-icon left>
              {{ icons.mdiKeyVariant }}
            </v-icon>
            <strong>Binding Keys</strong>
          </span>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-row class="ml-3 mt-3">
            <v-col
              sm="12"
            >
              <v-alert
                type="info"
                text
              >
                Select Source and Target Fields To Bind The Data On - Fields {{ selectedTask.forceUniqueKeyOnBind ? 'MUST' : 'Should' }} Contain Unique Values
              </v-alert>
            </v-col>
            <v-col
              v-if="selectedTask.bindingKeyText"
              sm="12"
            >
              <v-alert
                text
                color="primary"
              >
                {{ selectedTask.bindingKeyText }}
              </v-alert>
            </v-col>
            <v-col
              sm="12"
              md="6"
            >
              <v-select
                v-model="bindingData.bindingKeys.source"
                :items="queryData.headers"
                outlined
                clearable
                :rules="[v => !!v || 'Field is required']"
                label="Source Key Field"
              >
              </v-select>
            </v-col>
            <v-col
              sm="12"
              md="6"
            >
              <v-select
                v-model="bindingData.bindingKeys.target"
                :items="targetKeyItems"
                outlined
                clearable
                :rules="[v => !!v || 'Field is required']"
                label="Target Key Field"
                :hint="selectedTask.forceUniqueKeyOnBind ? 'Only Showing Unique Keys' : ''"
                :persistent-hint="selectedTask.forceUniqueKeyOnBind ? true : false"
              >
              </v-select>
            </v-col>
          </v-row>
          <v-row>
            <v-col :align="'end'">
              <v-btn
                :loading="loading"
                :disabled="!bindingData.bindingKeys.source || !bindingData.bindingKeys.target"
                color="primary"
                @click="buttonProceedToBinding()"
              >
                NEXT
              </v-btn>
            </v-col>
          </v-row>
        </v-expansion-panel-content>
      </v-expansion-panel>
      <v-expansion-panel :disabled="activePanels === 0">
        <v-expansion-panel-header>
          <span>
            <v-icon left>
              {{ icons.mdiTransitConnectionHorizontal }}
            </v-icon>
            <strong>Data Binding</strong>
          </span>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-row>
            <v-col sm="12">
              Using the target fields from <strong>{{ selectedConnector.name }}</strong>, select a data field from Snowflake to be sent to the destination.
            </v-col>
            <v-col sm="12">
              <p v-if="selectedTask.apiDocs">
                <strong>{{ selectedConnector.name }} API Docs: <a
                  :href="selectedTask.apiDocs"
                  target="_blank"
                >Here</a></strong>{{ selectedTask.docsRequireLogin ? ' (Requires Login)' : '' }}
              </p>
            </v-col>
          </v-row>
          <v-row>
            <v-col sm="6">
              <v-chip
                :color="bindingView === 'SourceToTarget' ? 'primary' : ''"
                @click="bindingView = 'SourceToTarget'; syncSourceTargetSelections('SourceToTarget')"
              >
                Bind Source To Target
              </v-chip>
              <v-chip
                class="ml-5"
                :color="bindingView === 'TargetToSource' ? 'primary' : ''"
                @click="bindingView = 'TargetToSource'; syncSourceTargetSelections('TargetToSource')"
              >
                Bind Target To Source
              </v-chip>
            </v-col>
            <v-col
              sm="6"
              :align="'end'"
            >
              <v-btn
                text
                outlined
                @click="autoMatchBindings()"
              >
                Auto Match Fields
              </v-btn>
              <v-btn
                class="ml-3"
                text
                outlined
                @click="clearBindings()"
              >
                Clear Bindings
              </v-btn>
            </v-col>
          </v-row>
          <!-- BINDING CARDS -->
          <!-- Bind Source To Target -->
          <v-row v-if="bindingView === 'SourceToTarget'">
            <v-col
              v-for="groupBindings in targetBindingGroupings"
              :key="groupBindings.id"
              sm="12"
            >
              <v-card>
                <div class="d-flex flex-column-reverse flex-md-row">
                  <div>
                    <v-card-title>
                      <span class="info--text">{{ groupBindings.group || '' }}</span>
                    </v-card-title>
                    <v-row>
                      <v-col
                        v-for="targetBinding in groupBindings.bindings"
                        :key="targetBinding.name"
                        sm="12"
                        md="6"
                      >
                        <v-card-title>
                          <h5>{{ targetBinding.label || targetBinding.name }}</h5>
                        </v-card-title>
                        <v-card-text class="d-flex align-center flex-wrap body-1">
                          <span class="text-sm">{{ targetBinding.name }} | <span class="error--text"><strong v-if="targetBinding.required"> Required</strong></span> {{ targetBinding.required && targetBinding.unique ? '|' : '' }} <strong v-if="targetBinding.unique">Must Be Unique</strong> {{ (targetBinding.unique && targetBinding.type || targetBinding.required && targetBinding.type) ? '|' : '' }} <span class="primary--text">{{ targetBinding.type }}</span></span>
                        </v-card-text>
                        <v-card-text>
                          {{ targetBinding.desc }}
                        </v-card-text>
                        <v-card-actions class="dense">
                          <v-row>
                            <v-col sm="12">
                              <v-select
                                v-model="targetBinding.bindingOn"
                                dense
                                :items="dropDownSelection"
                                outlined
                                clearable
                                :required="targetBinding.required"
                                :rules="targetBinding.required ? [v => !!v || 'Field is required'] : []"
                                :disabled="targetBinding.primaryKey"
                                :hint="targetBinding.bindingOn ? `Example: ${sampleData(targetBinding.bindingOn)}` : ''"
                                persistent-hint
                              ></v-select>
                            </v-col>
                          </v-row>
                        </v-card-actions>
                      </v-col>
                    </v-row>
                  </div>
                </div>
              </v-card>
            </v-col>
          </v-row>
          <!-- Bind Target To Source -->
          <v-row
            v-if="bindingView === 'TargetToSource'"
            align="center"
          >
            <v-col>
              <v-card
                v-for="sourceBinding in sourceBindings"
                :key="sourceBinding.name"
                dense
                sm="12"
              >
                <v-row>
                  <v-col
                    sm="6"
                    align="end"
                  >
                    <h3>{{ sourceBinding.name }}</h3>
                    <span>{{ sourceBinding.name && sourceBinding.name !== '' ? `Example: ${sampleData(sourceBinding.name)}` : '' }}</span>
                  </v-col>
                  <v-spacer></v-spacer>
                  <v-col
                    sm="6"
                    align="start"
                  >
                    <v-card-actions class="dense">
                      <v-row>
                        <v-col sm="12">
                          <v-select
                            v-model="sourceBinding.bindingOn"
                            dense
                            :items="bindingTargetToSourceDropdowns"
                            outlined
                            clearable
                            :disabled="sourceBinding.primaryKey"
                          ></v-select>
                        </v-col>
                      </v-row>
                    </v-card-actions>
                  </v-col>
                </v-row>
              </v-card>
            </v-col>
          </v-row>
          <v-row v-if="missingRequiredBindings.length > 0">
            <v-col sm="12">
              <v-alert
                type="error"
                text
              >
                The following Target Field(s) are required: {{ missingRequiredBindings.map(m =>`${m.label || m.name}[${m.name}]`).join(', ') }}
              </v-alert>
            </v-col>
          </v-row>
          <v-row class="mx-3 my-5">
            <v-spacer></v-spacer>
            <v-btn
              v-if="bindingData.bindingsReady"
              class="mt-3"
              :loading="loading"
              color="primary"
              @click="selectedTask.associations ? buttonProceedToAssociations() : saveBindings()"
            >
              {{ selectedTask.associations ? 'NEXT' : 'SAVE AND CONTINUE' }}
            </v-btn>
          </v-row>
        </v-expansion-panel-content>
      </v-expansion-panel>
      <v-expansion-panel
        v-if="selectedTask.associations"
        :disabled="!bindingData.bindingsReady"
      >
        <v-expansion-panel-header>
          <span>
            <v-icon left>
              {{ icons.mdiLinkPlus }}
            </v-icon>
            <strong>Associations</strong>
          </span>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-row>
            <v-col
              v-if="selectedTask.bindingKeyText"
              sm="12"
            >
              <v-alert
                text
                color="primary"
              >
                How would you like to associate these events in {{ selectedConnector.name }}?
              </v-alert>
            </v-col>
            <v-col
              sm="12"
              md="6"
            >
              <v-select
                v-model="associationType"
                :items="selectedTask.associations.types"
                :loading="loading"
                outlined
                clearable
                :rules="[v => !!v || 'Field is required']"
                label="Target Association Type"
                @change="loadAssociationTypes()"
              >
              </v-select>
            </v-col>
            <v-col
              sm="12"
              md="6"
            >
              <v-select
                v-if="associationLabelList"
                v-model="associationLabelName"
                :items="associationLabelList"
                outlined
                clearable
                :rules="[v => !!v || 'Field is required']"
                label="Association Label"
              >
              </v-select>
            </v-col>
          </v-row>
          <v-row>
            <v-col
              sm="12"
              md="6"
            >
              <v-select
                v-if="associationDestinationBindingList"
                v-model="associationSourceBindingName"
                :items="queryData.headers"
                outlined
                clearable
                :rules="[v => !!v || 'Field is required']"
                label="Association Source Binding Name (From Snowflake)"
              >
              </v-select>
            </v-col>
            <v-col
              sm="12"
              md="6"
            >
              <v-select
                v-if="associationDestinationBindingList"
                v-model="associationDestinationBindingName"
                :items="associationDestinationBindingList"
                outlined
                clearable
                :rules="[v => !!v || 'Field is required']"
                :label="`Association Target Binding Name (In ${selectedConnector.name})`"
                :hint="selectedTask.forceUniqueKeyOnBind ? 'Only Showing Unique Keys' : ''"
                :persistent-hint="selectedTask.forceUniqueKeyOnBind ? true : false"
              >
              </v-select>
            </v-col>
          </v-row>
          <v-row class="mx-3 my-5">
            <v-spacer></v-spacer>
            <v-btn
              :disabled="!bindingData.bindingsReady"
              class="mt-3"
              :loading="loading"
              color="primary"
              @click="saveBindings()"
            >
              SAVE AND CONTINUE
            </v-btn>
          </v-row>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>

<script>
import { mdiContentSaveOutline, mdiKeyVariant, mdiLinkPlus, mdiTransitConnectionHorizontal } from '@mdi/js'

import { computed, inject, onMounted, ref, watch } from '@vue/composition-api'

import { setBindingData } from './bindingScripts'

import { executeAssociationFunction } from '../binding-creation/BindingCreationScripts'

import moment from 'moment-timezone'

import _ from 'lodash'

export default {
  setup(none, { emit }) {
    // ————————————————————————————————————
    //* ——— Parent Injections
    // ————————————————————————————————————
    const isEdit = inject('isEdit')
    const dataBindings = inject('dataBindings')
    const selectedConnector = inject('selectedConnector')
    const queryData = inject('queryData')
    const selectedTask = inject('selectedTask')
    const bindingAuth = inject('bindingAuth')
    const taskProp = inject('taskProp')

    // ————————————————————————————————————
    //* ——— UI Variables
    // ————————————————————————————————————
    const activePanels = ref(0)
    const sourceBindings = ref([])
    const targetBindings = ref([])
    const associationType = ref(null)
    const associationLabelName = ref(null)
    const associationLabelList = ref(null)
    const associationSourceBindingName = ref(null)
    const associationDestinationBindingName = ref(null)
    const associationDestinationBindingList = ref(null)
    const bindingView = ref('SourceToTarget') // View Bindings by SourceToTarget or TargetToSource

    const loading = ref(false)

    const sampleData = field => {
      const maxSamples = 5 // Max Amount Of Sample Values To Show
      let sampleOutput = [...new Set(queryData.value.queryResults.map(m => m[field]))]
      sampleOutput = sampleOutput.filter(f => f !== '' && f !== null)

      // Fix Dates
      sampleOutput.forEach((sqlRecord, i) => {
        if (typeof sqlRecord?.getMonth === 'function') {
          sampleOutput[i] = moment(sqlRecord).format()
        }
      })
      sampleOutput = sampleOutput.slice(0, maxSamples).join(', ')

      return sampleOutput
    }

    // ————————————————————————————————————
    //* ——— BindingData Object
    // ————————————————————————————————————
    const bindingData = ref({
      bindingKeys: { source: null, target: null },
      bindingsReady: false,
      bindings: [],
    })

    const targetBindingGroupings = computed(() => {
      // If Data Contains Groups - Group Target Bindings Together Into Groups
      const targetBindingArray = []
      targetBindings.value.forEach(binding => {
        const groupId = binding.groupId || null
        const group = binding.group || null
        const groupIndex = targetBindingArray.findIndex(i => i.groupId === groupId)
        if (groupIndex > -1) {
          targetBindingArray[groupIndex].bindings.push(binding)
        } else {
          targetBindingArray.push({ group, groupId, bindings: [binding] })
        }
      })

      return targetBindingArray
    })

    // Check For Missing Required Bindings or Missing Keys
    // Sets bindingsReady variable
    const missingRequiredBindings = computed(() => {
      const missingRequired = []
      const { source: bindingKeySource, target: bindingKeyTarget } = bindingData.value.bindingKeys
      if (bindingView.value === 'SourceToTarget') {
        targetBindings.value.forEach(binding => {
          if (binding.required && !binding.bindingOn) {
            missingRequired.push(binding)
          }
        })
      } else {
        const requiredBindings = targetBindings.value.filter(f => f.required)
        requiredBindings.forEach(binding => {
          const match = sourceBindings.value.filter(f => f.bindingOn === binding.name)[0]
          if (!match) {
            missingRequired.push(binding)
          }
        })
      }
      if (missingRequired.length < 1 && bindingKeySource && bindingKeyTarget) {
        bindingData.value.bindingsReady = true
      } else bindingData.value.bindingsReady = false

      return missingRequired
    })

    // function emitBindingData() {
    //   emit('setEmittedBindingData', bindingData.value)
    // }

    function buildBindingData() {
      if (bindingView.value === 'SourceToTarget') {
        bindingData.value.bindings = targetBindings.value.map(m => ({ source: m.bindingOn, target: m.name })).filter(f => f.source && f.target)
      } else {
        bindingData.value.bindings = sourceBindings.value.map(m => ({ source: m.name, target: m.bindingOn })).filter(f => f.source && f.target)
      }

      // If Task Prop
      if (taskProp.value?.taskProp) bindingData.value.taskProp = taskProp.value.taskProp

      // Build Associations if Applicable
      if (associationType.value && associationSourceBindingName.value && associationDestinationBindingName.value) {
        const associationsData = { associationType: associationType.value, associationSourceBindingName: associationSourceBindingName.value, associationDestinationBindingName: associationDestinationBindingName.value }
        if (associationLabelName.value) associationsData.associationLabel = associationLabelName.value
        bindingData.value.associations = associationsData
      }
    }

    // ——————————————————————————————————————————
    //* ——— Save Binding Data
    // ——————————————————————————————————————————
    const saveBindings = async () => {
      if (missingRequiredBindings.value.length > 0) return
      buildBindingData()
      emit('saveBindings', bindingData.value)
    }

    // ————————————————————————————————————
    //* ——— Dropdown Lists
    // ————————————————————————————————————
    const targetKeyItems = computed(() => {
      if (selectedTask.value.forceUniqueKeyOnBind) {
        return dataBindings.value.filter(f => f.unique).map(m => ({ text: `${m[selectedTask.value.keys.name]}${m.type ? ` | ${m.type}` : ''}`, value: m.name }))
      }
      if (dataBindings.value) {
        return dataBindings.value.map(m => ({ text: `${m.name}${m.type ? ` | ${m.type}` : ''}`, value: m.name }))
      }

      return []
    })

    const dropDownSelection = ref([])

    watch(queryData, to => {
      if (to) {
        dropDownSelection.value = queryData.value.headers.sort((a, b) => (a.text > b.text ? 1 : -1))
      } else {
        dropDownSelection.value = []
      }
    })

    const bindingTargetToSourceDropdowns = computed(() => {
      const dropdowns = []
      targetBindings.value.forEach(binding => {
        dropdowns.push({ text: `${binding.name} | ${binding.type}${binding.required ? ' | REQUIRED' : ''}`, value: binding.name })
      })

      return _.sortBy(dropdowns, 'value')
    })

    // Gets SourceAssociationColumns and DestinationAssociationColumns for CUSTOM "associationsFunction" from connector
    // Custom Function Needs to Return associationDestinationBindingList and associationLabelList [optional] (HubSpot)
    const loadAssociationTypes = async () => {
      try {
        if (!associationType.value) return

        // Get Object Properties From The Selected Association Type
        loading.value = true
        const payload = { associationType: associationType.value, connector: selectedConnector.value, task: selectedTask.value, auth: bindingAuth.value }
        if (taskProp.value?.taskProp) payload.taskProp = taskProp.value.taskProp
        const response = await executeAssociationFunction(payload)
        associationDestinationBindingList.value = response.associationDestinationBindingList

        if (response.associationLabelList) {
          associationLabelList.value = response.associationLabelList
        }

        loading.value = false
      } catch (error) {
        loading.value = false
        const errorText = `Failed Getting Association Types. ERROR: ${error}`
        console.trace('\u001b[1;31m', errorText)
        throw errorText
      }
    }

    // ————————————————————————————————————
    //* ——— Binding Functions
    // ————————————————————————————————————

    // Auto Match Similar Field Names
    function autoMatchBindings() {
      const targetBindingMatches = []
      const sourceBindingMatches = []
      if (bindingView.value === 'SourceToTarget') {
        targetBindings.value.forEach(binding => {
          if (!binding.primaryKey && !binding.bindingOn) {
            const match = sourceBindings.value.filter(f => f.name.toUpperCase() === binding.name.toUpperCase())[0]
            if (match) {
              targetBindingMatches.push({ ...binding, bindingOn: match.name })
            } else {
              targetBindingMatches.push({ ...binding })
            }
          } else {
            targetBindingMatches.push({ ...binding })
          }
        })
        targetBindings.value = targetBindingMatches || []
      } else {
        sourceBindings.value.forEach(binding => {
          if (!binding.primaryKey && !binding.bindingOn) {
            const match = targetBindings.value.filter(f => f.name.toUpperCase() === binding.name.toUpperCase())[0]
            if (match) {
              sourceBindingMatches.push({ ...binding, bindingOn: match.name })
            } else {
              sourceBindingMatches.push({ ...binding })
            }
          } else {
            sourceBindingMatches.push({ ...binding })
          }
        })
        sourceBindings.value = sourceBindingMatches
      }
    }

    function applyPrimaryKeyRestraints() {
      // Clear Previous Source Keys If Needed
      sourceBindings.value.forEach((binding, i) => delete sourceBindings.value[i].primaryKey)

      // Find Source and Target Keys and Apply
      targetBindings.value.forEach((binding, i) => {
        // Remove Previous Keys If Needed
        if (binding.primaryKey) {
          delete targetBindings.value[i].primaryKey
        }
        const bindingPayload = binding
        if (binding.name === bindingData.value.bindingKeys.target) {
          // Add Primary Key to Target
          bindingPayload.bindingOn = bindingData.value.bindingKeys.source
          bindingPayload.primaryKey = true

          // Add Primary Key to Source

          const sourceKeyIndex = sourceBindings.value.findIndex(f => f.name === bindingData.value.bindingKeys.source)
          sourceBindings.value[sourceKeyIndex].bindingOn = bindingData.value.bindingKeys.target
          sourceBindings.value[sourceKeyIndex].primaryKey = true
        }
        targetBindings.value[i] = bindingPayload
      })
    }

    function syncSourceTargetSelections(bindingType) {
      // Clear Previous Values From Source
      if (bindingType === 'SourceToTarget') {
        targetBindings.value.forEach((binding, i) => {
          if (!binding.primaryKey) {
            targetBindings.value[i].bindingOn = null
          }
        })
      } else {
        sourceBindings.value = sourceBindings.value.map(m => ({ ...m, bindingOn: m.primaryKey ? m.bindingOn : null }))
      }

      // Find Changes Between Binding Directions
      targetBindings.value.forEach((binding, i) => {
        if (bindingType === 'SourceToTarget') {
          const sourceBinding = sourceBindings.value.filter(f => f?.bindingOn === binding?.name)[0]
          if (sourceBinding && !binding.primaryKey) {
            targetBindings.value[i].bindingOn = sourceBinding.name
          }
        } else {
          const sourceIndex = sourceBindings.value.findIndex(idx => idx.name === binding.bindingOn)
          if (sourceIndex > -1) {
            if (!sourceBindings.value[sourceIndex].primaryKey) {
              sourceBindings.value[sourceIndex].bindingOn = binding.name
            }
          }
        }
      })
    }

    function buttonProceedToAssociations() {
      if (missingRequiredBindings.value.length > 0) return
      activePanels.value += 1
    }

    function buttonProceedToBinding() {
      loading.value = true
      applyPrimaryKeyRestraints()
      activePanels.value += 1
      loading.value = false
    }

    function clearBindings() {
      // Clear For Target
      const emptyTargetBindings = []
      targetBindings.value.forEach(binding => {
        if (binding.bindingOn && !binding.primaryKey) {
          emptyTargetBindings.push({ ...binding, bindingOn: null })
        } else {
          emptyTargetBindings.push({ ...binding })
        }
      })

      // Clear For Source
      const emptySourceBindings = []
      sourceBindings.value.forEach(binding => {
        if (binding.bindingOn && !binding.primaryKey) {
          emptySourceBindings.push({ ...binding, bindingOn: null })
        } else {
          emptySourceBindings.push({ ...binding })
        }
      })

      targetBindings.value = emptyTargetBindings || []
      sourceBindings.value = emptySourceBindings || []
    }

    // ———————————————————————————————————————————————————————
    //* ——— Sync In Data From Binding Edit Object - If Edit
    // ———————————————————————————————————————————————————————
    const editBindingObject = inject('bindingData')

    async function buildBindingValuesFromEditObject() {
      const bindings = []
      if (bindingView.value === 'SourceToTarget') {
        // Generate found targetBindings and unfound sourceBindings
        dataBindings.value.forEach(binding => {
          const bindingMatch = bindingData.value.bindings.filter(f => f.target === binding.name)[0]
          if (bindingMatch) {
            bindings.push({ ...binding, bindingOn: bindingMatch.source })
          } else {
            bindings.push(binding)
          }
          targetBindings.value = bindings || []
        })
      } else {
        // Generate found sourceBindings Records
        sourceBindings.value = dataBindings.value.map(m => ({ name: m.source, bindingOn: m.target }))
      }
      if (editBindingObject.value.associations) {
        if (editBindingObject.value.associations.associationType) {
          associationType.value = editBindingObject.value.associations.associationType
          await loadAssociationTypes()
        }
        if (editBindingObject.value.associations.associationDestinationBindingName) associationDestinationBindingName.value = editBindingObject.value.associations.associationDestinationBindingName
        if (editBindingObject.value.associations.associationLabel) associationLabelName.value = editBindingObject.value.associations.associationLabel
        if (editBindingObject.value.associations.associationSourceBindingName) associationSourceBindingName.value = editBindingObject.value.associations.associationSourceBindingName
      }
    }

    // —————————————————————————————————————————————————————————
    //* ——— Binding Initiation - Sets Binding Object if Edit
    // —————————————————————————————————————————————————————————
    onMounted(async () => {
      emit('showSaveButton', false) // Hides Parent Save Button - This Binding Will NOT Emit Back
      sourceBindings.value = queryData.value.headers.map(m => ({ name: m.value }))
      targetBindings.value = dataBindings.value || []
      if (isEdit.value) {
        if (editBindingObject.value.associations) bindingData.value.associations = []
        bindingData.value = { ...bindingData.value, ...setBindingData(bindingData.value, editBindingObject.value) }
        buildBindingValuesFromEditObject()
      } else {
        autoMatchBindings()
      }
    })

    return {
      // Binding Data
      bindingData,
      dataBindings,
      selectedTask,
      selectedConnector,
      sourceBindings,
      targetBindings,
      targetBindingGroupings,
      missingRequiredBindings,
      taskProp,

      // UI Variables
      bindingView,
      queryData,
      activePanels,
      associationType,
      associationLabelName,
      associationLabelList,
      associationSourceBindingName,
      associationDestinationBindingName,
      associationDestinationBindingList,
      loading,
      sampleData,

      // Dropdown Lists
      targetKeyItems,
      dropDownSelection,
      bindingTargetToSourceDropdowns,
      loadAssociationTypes,

      // Binding Functions
      buttonProceedToBinding,
      buttonProceedToAssociations,
      syncSourceTargetSelections,
      autoMatchBindings,
      clearBindings,
      saveBindings,

      icons: {
        mdiKeyVariant,
        mdiTransitConnectionHorizontal,
        mdiLinkPlus,
        mdiContentSaveOutline,
      },
    }
  },
}
</script>
