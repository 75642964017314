<template>
  <div class="mt-5">
    <span>
      <v-icon left>
        {{ icons.mdiTableLargePlus }}
      </v-icon>
      <strong>{{ bindingData.googleWorkbookName ? `${bindingData.googleWorkbookName} | ${bindingData.googleSheetsName || 'Sheet1'}` : 'Google Sheet Creation' }}</strong>
    </span>

    <!-- Create New or Update Existing Workbook -->
    <v-container class="ml-3 mt-5">
      <v-row>
        <v-col
          sm="12"
          md="6"
        >
          <v-select
            v-model="bindingData.workbookCreationMethod"
            outlined
            label="Create New or Update Existing Workbook"
            :items="[{ text: 'Create New Workbook', value: 'createWorkbook' }, { text: 'Update Existing Workbook', value: 'updateWorkbook' }]"
            @change="getWorkbookList()"
          ></v-select>
        </v-col>
        <v-col
          sm="12"
          md="6"
        >
          <v-text-field
            v-if="bindingData.workbookCreationMethod === 'createWorkbook'"
            v-model="bindingData.googleWorkbookName"
            outlined
            clearable
            label="Workbook Name"
            :rules="[v => !!v || 'Field is required']"
            @click:clear="bindingData.googleSheetsSyncType = null"
          >
          </v-text-field>
          <v-select
            v-if="bindingData.workbookCreationMethod === 'updateWorkbook'"
            v-model="bindingData.googleWorkbookId"
            :items="workbookList"
            :loading="loadingFields.workbookNames"
            outlined
            clearable
            label="Workbook Name"
            @click:clear="bindingData.googleSheetsSyncType = null"
            @change="getWorkbookSheetNamesFromId('workbook')"
          >
          </v-select>
        </v-col>
      </v-row>
    </v-container>
    <!-- Create New or Update Existing Sheet -->
    <template v-if="bindingData.googleWorkbookName">
      <v-container class="ml-3">
        <v-row>
          <v-col
            sm="12"
            md="6"
          >
            <v-select
              v-model="bindingData.sheetCreationMethod"
              :disabled="bindingData.workbookCreationMethod === 'createWorkbook'"
              outlined
              label="Create New or Update Existing Sheet"
              :items="[{ text: 'Create New Sheet', value: 'createSheet' }, { text: 'Update Existing Sheet', value: 'updateSheet' }]"
              @change="getSheetList()"
            ></v-select>
          </v-col>
          <v-col
            sm="12"
            md="6"
          >
            <v-text-field
              v-if="bindingData.sheetCreationMethod === 'createSheet'"
              v-model="bindingData.googleSheetsName"
              outlined
              clearable
              label="Sheet Name ( Optional )"
              @click:clear="bindingData.googleSheetsSyncType = null"
            >
            </v-text-field>
            <v-select
              v-if="bindingData.sheetCreationMethod === 'updateSheet'"
              v-model="bindingData.googleSheetId"
              :items="sheetsList"
              :loading="loadingFields.sheetName"
              outlined
              clearable
              label="Sheet Name"
              @change="getWorkbookSheetNamesFromId('sheet')"
              @click:clear="bindingData.googleSheetsSyncType = null"
            >
            </v-select>
          </v-col>
        </v-row>
        <!-- Folder Name and Sheet Shares List -->
        <template v-if="(bindingData.sheetCreationMethod === 'updateSheet' && bindingData.googleSheetsName) || bindingData.sheetCreationMethod === 'createSheet'">
          <v-row>
            <v-col
              sm="12"
              md="6"
            >
              <v-select
                v-model="bindingData.googleSheetsFolderId"
                :items="googleFolders"
                outlined
                clearable
                label="Workbook Folder Name ( Optional )"
                hint="Make sure the folder you want exists on Google Drive"
                persistent-hint
              >
              </v-select>
            </v-col>
            <v-col
              sm="12"
              md="6"
            >
              <v-text-field
                v-model="googleSheetsSharedWithString"
                outlined
                clearable
                label="Share Sheet With Emails ( Optional )"
                hint="Separate multiple emails with a comma | *Note: This Will Email The User"
                persistent-hint
                @blur="parseSharedEmails"
              >
              </v-text-field>
            </v-col>
          </v-row>
        </template>
      </v-container>
      <!-- Is Multiple Sheet -->
      <!-- Filter on Column -->
      <!-- ------------------ -->
      <template v-if="isMultiSheet && ((bindingData.workbookCreationMethod === 'createWorkbook' && bindingData.googleWorkbookName) || bindingData.sheetCreationMethod === 'createSheet' || (bindingData.sheetCreationMethod === 'updateSheet' && (bindingData.googleSheetsName || bindingData.googleSheetsName === 0)))">
        <v-row
          class="mt-5"
          no-gutters
        >
          <span>
            <v-icon left>
              {{ icons.mdiTableFilter }}
            </v-icon>
            <strong>{{ 'Filter On Column' }}</strong>
          </span>
        </v-row>
        <v-container class="mt-3 ml-3">
          <v-row>
            <v-col
              sm="12"
              md="4"
            >
              <v-select
                v-model="bindingData.multiSheetFilterColumn"
                :items="uniqueKeyList"
                outlined
                :label="'Filter On Column'"
                clearable
              ></v-select>
            </v-col>
            <v-col
              v-if="bindingData.multiSheetFilterColumn"
              sm="12"
              md="4"
            >
              <v-text-field
                v-model="bindingData.multiSheetFilterValue"
                outlined
                label="Filter Value"
                clearable
                :hint="`WHERE ${bindingData.multiSheetFilterColumn} = '${bindingData.multiSheetFilterValue}' (This Field is Case Sensitive, You Can Add Multiple Values Separated By Commas)`"
                persistent-hint
              ></v-text-field>
            </v-col>
            <v-col
              sm="12"
              md="4"
            >
              <v-checkbox
                v-if="bindingData.multiSheetFilterColumn"
                v-model="bindingData.multiSheetIncludeFilterColumn"
                label="Include Filter Column on Spreadsheet"
              >
              </v-checkbox>
            </v-col>
          </v-row>
        </v-container>
      </template>
      <!-- Sync Type For MULTI SHEET ONLY -->
      <!-- ------------------------------ -->
      <template v-if="(isMultiSheet && bindingData.multiSheetFilterValue) || (!isMultiSheet && ((bindingData.workbookCreationMethod === 'createWorkbook' && bindingData.googleWorkbookName) || bindingData.sheetCreationMethod === 'createSheet' || (bindingData.sheetCreationMethod === 'updateSheet' && (bindingData.googleSheetsName || bindingData.googleSheetsName === 0))))">
        <v-row
          class="mt-5"
          no-gutters
        >
          <span>
            <v-icon left>
              {{ icons.mdiTableSync }}
            </v-icon>
            <strong>{{ 'Sync Type' }}</strong>
          </span>
        </v-row>
        <v-container class="mt-3 ml-3">
          <v-row>
            <v-col
              sm="12"
              md="6"
            >
              <v-select
                v-model="bindingData.googleSheetsSyncType"
                :items="googleSheetsSyncTypeItems"
                outlined
                :label="'Sync Type'"
              ></v-select>
            </v-col>
            <v-col
              v-if="bindingData.googleSheetsSyncType === 'upsert'"
              sm="12"
              md="6"
            >
              <v-select
                v-model="bindingData.googleSheetsUpsertKey"
                :items="uniqueKeyList"
                outlined
                label="Unique Key for Updates"
                hint="Must Contain Unique Values"
                persistent-hint
                clearable
              ></v-select>
            </v-col>
          </v-row>
          <v-row>
            <v-col align="end">
              <v-btn
                :disabled="!bindingData.googleSheetsSyncType || (bindingData.googleSheetsSyncType === 'upsert' && !bindingData.googleSheetsUpsertKey)"
                class="success"
                @click="saveMultiSheetData()"
              >
                Save
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </template>
    </template>
  </div>
</template>
<script>
import { mdiTableLargePlus } from '@mdi/js'

import { computed, inject, onMounted, ref, watch } from '@vue/composition-api'

import { setBindingData } from '../../binding-views/bindingScripts'

import { googleSheetsGetSheetList, googleSheetsGetWorkbookList, googleSheetsGetWorkbookSheetNamesFromId, googleSheetsGoogleFolderList, googleSheetsObjectSchema } from './googleSheetScripts'

export default {
  props: {
    isSheetMultiSheet: {
      type: Boolean,
      required: false,
      default: false,
    },
    multiSheetData: {
      type: Object,
      required: false,
      default: () => {},
    },
  },
  setup(props, { emit }) {
    // ————————————————————————————————————
    //* ——— Parent Injections
    // ————————————————————————————————————
    const isMultiSheet = computed(() => props.isSheetMultiSheet)
    const isEdit = inject('isEdit')
    const editBindingObject = inject('bindingData')
    const queryData = inject('queryData')

    // const docId = inject('docId')
    // const account = inject('account')
    // const user = inject('user')
    // const selectedConnector = inject('selectedConnector')

    // const selectedTask = inject('selectedTask')
    // const snowBindingName = inject('snowBindingName')
    const bindingAuth = inject('bindingAuth')

    // ————————————————————————————————————
    //* ——— UI Variables
    // ————————————————————————————————————
    // const loading = ref(false)
    const errorMessage = ref(null)

    // const successMessage = ref(null)
    const workbookList = ref([])
    const sheetsList = ref([])
    const googleSheetsSharedWithString = ref(null)

    // const googleSheetMetaData = ref(null)
    const googleFolders = ref([])
    const loadingFields = ref({ workbookNames: false, sheetNames: false })

    // ————————————————————————————————————
    //* ——— BindingData Object
    // ————————————————————————————————————
    const bindingData = ref({}) // Derived from ./googleSheetScripts googleSheetsObjectSchema - Also Uses In GoogleSheetsMultipleSheetsBindings

    watch(bindingData, () => {
      if (!isMultiSheet.value) {
        const data = bindingData.value
        if ((data.googleSheetsSyncType && data.googleSheetsSyncType !== 'upsert') || (data.googleSheetsSyncType === 'upsert' && data.googleSheetsUpsertKey)) {
          bindingData.value.bindingsReady = true
          emit('setEmittedBindingData', bindingData.value)
          emit('setBindingsReady', true)
        } else if (bindingData.value) {
          bindingData.value.bindingsReady = false
          emit('setBindingsReady', false)
        }
      }
    })

    // Save Sheet Data To MultiSheet Parent Array
    const saveMultiSheetData = () => {
      bindingData.value.bindingsReady = true
      emit('setEmittedBindingData', bindingData.value)
    }

    // ————————————————————————————————————
    //* ——— Generated Dropdown Lists
    // ————————————————————————————————————
    const googleSheetsSyncTypeItems = [
      { text: 'Overwrite Sheet', value: 'overwrite' },
      { text: 'Append Rows To Bottom Of Sheet', value: 'append' },
      { text: 'Insert New Rows and Update Rows (Upsert)', value: 'upsert' },
    ]

    const uniqueKeyList = computed(() => queryData.value.headers)

    // ————————————————————————————————————
    //* ——— Binding Functions
    // ————————————————————————————————————

    // Create Array From Shared Emails Lists Containing Commas
    // { value: name@email.com, processed: false } // processed will be true after run and shared with user
    const parseSharedEmails = () => {
      if (!googleSheetsSharedWithString.value || googleSheetsSharedWithString.value === '') bindingData.value.googleSheetsSharedWith = null
      else if (googleSheetsSharedWithString.value.includes(',')) bindingData.value.googleSheetsSharedWith = googleSheetsSharedWithString.value.split(',').map(m => ({ value: m.replace(/ /g, ''), processed: false }))
      else bindingData.value.googleSheetsSharedWith = [{ value: googleSheetsSharedWithString.value, processed: false }]
    }

    const extractParsedSharedEmails = () => {
      googleSheetsSharedWithString.value = bindingData.value.googleSheetsSharedWith ? bindingData.value.googleSheetsSharedWith.map(m => m.value).join(',') : []
    }

    const googleFolderList = async () => {
      googleFolders.value = await googleSheetsGoogleFolderList(bindingAuth.value)
    }

    const getWorkbookList = async () => {
      if (bindingData.value.workbookCreationMethod === 'createWorkbook') bindingData.value.sheetCreationMethod = 'createSheet'
      else {
        loadingFields.value.workbookNames = true
        const response = await googleSheetsGetWorkbookList(bindingData.value, bindingAuth.value)
        if (response.success) workbookList.value = response.success
        else if (response.error) errorMessage.value = response.error
        else bindingData.value.sheetCreationMethod = 'createSheet'
        loadingFields.value.workbookNames = false
      }
    }

    const getSheetList = async () => {
      loadingFields.value.sheetNames = true
      sheetsList.value = await googleSheetsGetSheetList(bindingData.value, bindingAuth.value)
      loadingFields.value.sheetNames = false
    }

    // const parseSharedWithEmails = toType => googleSheetsParseSharedWithEmails(bindingData.value, toType)

    const getWorkbookSheetNamesFromId = type => {
      bindingData.value = { ...bindingData.value, ...googleSheetsGetWorkbookSheetNamesFromId(bindingData.value, editBindingObject.value, workbookList.value, sheetsList.value, type) }
    }

    // ———————————————————————————————————————————————————————
    //* ——— Sync In Data From Binding Edit Object - If Edit
    // ———————————————————————————————————————————————————————
    watch(editBindingObject, () => {
      bindingData.value = { ...bindingData.value, ...setBindingData(bindingData.value, editBindingObject.value) }
      emit('setEmittedBindingData', bindingData.value)
    })

    // —————————————————————————————————————————————————————————
    //* ——— Binding Initiation - Sets Binding Object if Edit
    // —————————————————————————————————————————————————————————
    onMounted(async () => {
      bindingData.value = googleSheetsObjectSchema
      await googleFolderList() // Load Folder List From Google Drive
      if (isMultiSheet.value) {
        bindingData.value = props.multiSheetData
        extractParsedSharedEmails()
      } else if (isEdit.value) {
        // If Editing Object
        bindingData.value = { ...bindingData.value, ...setBindingData(bindingData.value, editBindingObject.value) }
        emit('setEmittedBindingData', bindingData.value)
        extractParsedSharedEmails()
        await Promise.all([getWorkbookList(), getSheetList()])
      }
    })

    return {
      // BindingData
      bindingData,
      saveMultiSheetData,

      // UI Variables
      isMultiSheet,
      loadingFields,
      errorMessage,
      googleFolders,
      googleSheetsSharedWithString,

      // DropDownLists
      workbookList,
      sheetsList,
      googleSheetsSyncTypeItems,
      uniqueKeyList,

      // Functions
      getWorkbookList,
      getSheetList,
      parseSharedEmails,
      getWorkbookSheetNamesFromId,

      icons: {
        mdiTableLargePlus,
      },
    }
  },
}
</script>
