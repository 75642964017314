<template>
  <div>
    <v-expansion-panels v-model="panels">
      <v-expansion-panel>
        <v-expansion-panel-header>
          <span><v-icon
            color="info"
            left
          >{{ icons.mdiSnowflake }}</v-icon><strong>Snowflake Connection Settings</strong></span>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-alert
            text
            type="info"
          >
            How Would You Like To Connect To Snowflake?
          </v-alert>
          <v-select
            v-model="snowflakeConnection"
            outlined
            :items="snowflakeConnectionOptions"
          ></v-select>
          <v-alert
            v-if="snowflakeConnection === 'system'"
            text
            type="warning"
          >
            Make Sure The User <strong>{{ systemUser.username }}</strong> with Role: <strong>{{ systemUser.role }}</strong> has (usage, select) access to the data you need to read and (usage, write) access to the schemas you want to write to.
          </v-alert>
          <v-row
            v-if="snowflakeConnection !== 'system'"
            dense
          >
            <v-col sm="12">
              <v-alert text>
                Add Snowflake Credentials For This Connection
              </v-alert>
            </v-col>
            <v-col
              md="6"
              sm="12"
            >
              <v-text-field
                v-model="snowflakeUser"
                outlined
                label="Snowflake User"
              >
              </v-text-field>
            </v-col>
            <v-col
              md="6"
              sm="12"
            >
              <v-text-field
                v-model="snowflakePassword"
                outlined
                label="Snowflake Password"
              >
              </v-text-field>
            </v-col>
            <v-col
              md="6"
              sm="12"
            >
              <v-text-field
                v-model="snowflakeRole"
                outlined
                label="Snowflake Role"
              >
              </v-text-field>
            </v-col>
            <v-col
              md="6"
              sm="12"
            >
              <v-text-field
                v-model="snowflakeWarehouse"
                outlined
                label="Snowflake Warehouse"
              >
              </v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col align="end">
              <v-btn
                v-if="snowflakeConnection !== 'system'"
                :disabled="!snowflakeUser || !snowflakePassword || !snowflakeRole || !snowflakeWarehouse"
                class="mr-3"
                color="primary"
                @click="panels = 1"
              >
                Verify Credentials
              </v-btn>
              <v-btn
                v-if="snowflakeConnection === 'system'"
                color="primary"
                @click="panels = 1"
              >
                Next
              </v-btn>
              <v-btn
                v-if="snowflakeConnection !== 'system'"
                :disabled="!customCredentialsVerified"
                color="primary"
                @click="panels = 1"
              >
                Next
              </v-btn>
            </v-col>
          </v-row>
        </v-expansion-panel-content>
      </v-expansion-panel>
      <v-expansion-panel>
        <v-expansion-panel-header>
          <span><v-icon
            color="primary"
            left
          >{{ icons.mdiKeyChainVariant }}</v-icon><strong>{{ selectedConnector.name }} Connection Settings</strong></span>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <component
            :is="authName"
            @write-to-firestore="writeAuthToFirestore"
          ></component>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>

<script>
import { writeDoc } from '@/firestore'

import store from '@/store'

import { mdiKeyChainVariant, mdiSnowflake } from '@mdi/js'

// eslint-disable-next-line object-curly-newline
import serverConfig from '@serverConfig'

// eslint-disable-next-line object-curly-newline
import { computed, inject, ref } from '@vue/composition-api'

import DefaultAuthentication from './DefaultAuthentication.vue'

import GoogleSheetsAuth from '../../connectors/googleSheets/GoogleSheetsAuth.vue'

import HubspotAuth from '../../connectors/hubspot/HubspotAuth.vue'

import s3Auth from '../../connectors/s3/S3Auth.vue'

export default {
  components: { DefaultAuthentication, s3Auth, HubspotAuth, GoogleSheetsAuth },
  setup(none, { emit }) {
    const selectedConnector = inject('selectedConnector')
    const panels = ref(0)
    const snowflakeConnection = ref('system')
    const snowflakeUser = ref(null)
    const snowflakeRole = ref(null)
    const snowflakePassword = ref(null)
    const snowflakeWarehouse = ref(null)
    const snowflakeEncryptedPassword = ref(null)
    const customCredentialsVerified = ref(false)
    const account = computed(() => store.state.accountProfile)
    const user = inject('user')
    const systemUser = computed(() => store.state.accountProfile.snowflakeCredentials)
    const authName = computed(() => selectedConnector.value?.authentication?.name)
    const snowflakeConnectionOptions = [
      { text: `${serverConfig.name} User and Role`, value: 'system' },
      { text: 'Other Snowflake Credentials', value: 'otherCredentials' },
    ]

    const snowflakeConnectionParams = computed(() => {
      if (snowflakeConnection.value === 'system') {
        return { isSystem: true }
      }

      // If Not Using System Account
      return {
        isSystem: false,
        snowflakeUser: snowflakeUser.value,
        snowflakePassword: snowflakeEncryptedPassword.value,
        snowflakeRole: snowflakeRole.value,
      }
    })

    // todo: customCredentials
    // const verifyCustomCredentials = () => {
    //   try {
    //     // Test Credentials by Getting User Data From Snowflake
    //     const getUserResponse = await snowflakeQuery('select current_user;')
    //     snowflakeUsername.value = getUserResponse.rows[0].CURRENT_USER
    //     const getUserDetailsFromSnowflake = await snowflakeQuery(`describe user "${snowflakeUsername.value}"`)
    //     const userData = getUserDetailsFromSnowflake.rows

    //     // Store Important User Data
    //     snowflakeVerifiedUserData.value = {
    //       username: userData.filter(f => f.property === 'LOGIN_NAME')[0].value,
    //       role: userData.filter(f => f.property === 'DEFAULT_ROLE')[0].value,
    //       warehouse: userData.filter(f => f.property === 'DEFAULT_WAREHOUSE')[0].value,
    //     }

    //     return 'Success'
    //   } catch (err) {
    //     const errorMessage = err
    //     throw errorMessage
    //   }
    // }

    const writeAuthToFirestore = async auth => {
      const connectionId = Date.now().toString()

      try {
        const data = {
          ...auth,
          account: account.value.id,
          connectionId,
          snowflakeCredentials: snowflakeConnectionParams.value,
          isDeleted: false,
          isEnabled: true,
          createdByUserId: user.value.id,
          createdAt: new Date(),
          modifiedAt: new Date(),
        }
        // eslint-disable-next-line object-curly-newline
        await writeDoc({ collection: 'snowBindings', docId: 'connectors', subCollection: 'auth', subCollectionDocId: connectionId, data })
      } catch (err) {
        console.log(err)
      }

      // Close Dialog
      emit('validated', { ...auth, connectionId })
    }

    return {
      authName,
      selectedConnector,
      customCredentialsVerified,
      snowflakeUser,
      snowflakePassword,
      snowflakeRole,
      snowflakeWarehouse,
      systemUser,
      snowflakeConnection,
      snowflakeConnectionOptions,
      panels,
      writeAuthToFirestore,
      icons: { mdiSnowflake, mdiKeyChainVariant },
    }
  },
}
</script>
